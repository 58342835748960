// components/ErrorMessage.js

import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { uiRed, font600, uiGreen } from '../styles';

const SuccessMessage = ({ success, visible }) => {
  if (!success || !visible) {
    return null;
  }

  return <Text style={styles.successText}>✔️ {success}</Text>;
};

const styles = StyleSheet.create({
  successText: {
    color: uiGreen,
    fontSize: 14,    
    fontWeight: '600',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 30,    
    fontFamily: font600,
    paddingHorizontal: 40,
    maxWidth: 360
  },
});

export default SuccessMessage;