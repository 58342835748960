import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, TouchableWithoutFeedback, Image, StyleSheet, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { darkGrey, grey, font300, font400, font500, font600, font700, offWhite, lightGrey } from '../styles';

const CustomTabBar = ({ state, descriptors, navigation }) => {

    return (
        <SafeAreaView edges={['bottom']}>
            <View style={styles.tabBar}>
                {state.routes.map((route, index) => {
                    if (route.name === 'Profile Settings') {
                        return null; // Exclude the Profile tab
                    }

                    const { options } = descriptors[route.key];
                    const isFocused = state.index === index;

                    const onPress = () => {
                        const event = navigation.emit({
                            type: 'tabPress',
                            target: route.key,
                            canPreventDefault: true,
                        });

                        if (!isFocused && !event.defaultPrevented) {
                            navigation.navigate(route.name);
                        }
                    };

                    const onLongPress = () => {
                        navigation.emit({
                            type: 'tabLongPress',
                            target: route.key,
                        });
                    };

                    const icon = options.tabBarIcon({ focused: isFocused });

                    return (
                        <TouchableOpacity
                            key={route.key}
                            accessibilityRole="button"
                            accessibilityState={isFocused ? { selected: true } : {}}
                            accessibilityLabel={options.tabBarAccessibilityLabel}
                            testID={options.tabBarTestID}
                            onPress={onPress}
                            onLongPress={onLongPress}
                            style={[styles.tab, isFocused && styles.activeTab]}
                        >
                            {icon}
                        </TouchableOpacity>
                    );
                })}
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    tabBar: {
        flexDirection: 'row',
        height: 78,
        backgroundColor: darkGrey,
        borderTopWidth: 2,
        borderTopColor: grey
    },
    tab: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 2,
        borderTopColor: 'transparent',
    },
    activeTab: {
        borderTopColor: offWhite,
    },
});

export default CustomTabBar;