// components/ErrorMessage.js

import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { uiRed, font600, uiOrange } from '../styles';

const ErrorMessage = ({ error, visible }) => {
  if (!error || !visible) {
    return null;
  }

  return <Text style={styles.errorText}>⚠️ {error}</Text>;
};

const styles = StyleSheet.create({
  errorText: {
    color: uiOrange,
    fontSize: 14,    
    fontWeight: '600',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 30,    
    fontFamily: font600,
    paddingHorizontal: 40,
    maxWidth: 360
  },
});

export default ErrorMessage;