import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Image, Animated, Easing, Text as RNText } from 'react-native';
import { Avatar, Text} from '@ui-kitten/components';
import { font400, font600, offWhite, uiGreen, windowWidth, lightGrey, darkGrey, grey, font100 } from '../styles';
import { Button } from 'react-native-web';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const StaffSlice = (props) => {
    let {displayName, nickname, role, photoURL, keyHolder, hasPet, mac_addr, dogName, dogPhotoURL } = props.staffInfo.data;
    let { dogOwner, livePeople, isToday, isBookedIn, currentUID, uid, holidayType, typeOfAttendance, holidayLength, isRemote, leaveType } = props;
    let isIn = false;
    let isCurrentUser = false;

    if(holidayType === 'Day'){
        holidayType = "all day"
    } else if(holidayType === "AM") {
        holidayType = "morning only"
    } else if(holidayType === "PM") {
        holidayType = "afternoon only"
    } else {
        holidayType = ""
    }

    if(livePeople?.length && mac_addr){
        isIn = livePeople.filter((item) => {
            return mac_addr.includes(item.mac)
        });
    }

    if (currentUID && uid) {
        isCurrentUser = currentUID === uid;
    }

    const isOnLeave = holidayLength !== null;
    const isGhost = !isBookedIn && isIn.length > 0;

    const [showDetails, setShowDetails] = useState(false);
    const animation = useRef(new Animated.Value(0)).current;
    const contentHeight = useRef(0);
    const contentRef = useRef(null);

    const toggleDetails = () => {
        if (!isCurrentUser) {
            if (!showDetails) {
                contentRef.current.measure((x, y, width, height) => {
                    contentHeight.current = height;
                    setShowDetails(true);
                    setTimeout(() => {
                        Animated.timing(animation, {
                            toValue: height,
                            duration: 300,
                            easing: Easing.ease,
                            useNativeDriver: false,
                        }).start();
                    }, 0);
                });
            } else {
                Animated.timing(animation, {
                    toValue: 0,
                    duration: 300,
                    easing: Easing.ease,
                    useNativeDriver: false,
                }).start(() => {
                    setShowDetails(false);
                });
            }
        }
    }
    
    return(
        <>
            <View style={styles.details}>
                <View style={styles.profileHolder}>
                    <Avatar size='medium' source={{uri:photoURL}} />
                    {isToday && (!isRemote || isGhost ) &&
                        <View style={[styles.isInIcon, isIn.length && {backgroundColor: uiGreen}]} />
                    }
                </View>
                <View style={styles.nameHolder}>
                    <Text style={styles.name} category='h6'>
                        {!showDetails && nickname}
                    </Text>
                    
                    <Animated.View style={[styles.additionalDetails, { height: animation }]}>
                        <View
                            style={styles.hiddenContent}
                            ref={contentRef}
                            onLayout={(event) => {
                                const { height } = event.nativeEvent.layout;
                                contentHeight.current = height;
                                if (showDetails) {
                                    animation.setValue(height);
                                }
                            }}
                        >
                            {showDetails && !isCurrentUser && (
                                <>
                                    <Text style={styles.name} category='h6'>
                                        {displayName}
                                    </Text>
                                    <Text style={styles.detailText}>
                                        {role}
                                    </Text>
                                </>
                            )}
                        </View>
                    </Animated.View>
                    {isBookedIn && typeOfAttendance && 
                        <Text style={styles.attendanceText} category='p1'> {typeOfAttendance}</Text>
                    }
                    {leaveType &&
                        <Text style={styles.attendanceText} category='p1'>
                            {/* {leaveType !== "Holiday" ? 'Other leave' : ''}  */}
                            {leaveType === 'Holiday' ? `Annual leave, ${holidayType}` : `${leaveType}`}
                        </Text>
                    }
                </View>    
                <View style={styles.stateHolder}>
                    {dogOwner &&
                        <>
                            <Text style={styles.dogName}>with {dogName && dogName.length > 0 ? dogName : 'dog'}</Text>
                            <Avatar size='medium' style={[styles.dogPic]}
                            source={{ uri: dogPhotoURL || require("../assets/paw-profile.png") }}/>
                            
                        </>
                    }
                    <View style={styles.availability}>
                        {isCurrentUser ?
                            <MaterialCommunityIcons name="pencil-outline" size={23} style={styles.pencilIcon} />
                        :
                            <MaterialCommunityIcons name="information-outline" size={23} style={styles.infoIcon} onPress={toggleDetails} />
                        }
                    </View>
                </View>      
            </View>
        </>
    )
};   

const styles = StyleSheet.create({
    // Dropdown info
    infoIcon: {
        marginLeft: 5,
        color: grey,
    },
    additionalDetails: {
        overflow: 'hidden',
    },
    detailText: {
        fontSize: 14,
        color: offWhite,
        display: 'flex',
        alignItems: 'center'
    },
    pencilIcon: {
        marginLeft: 5,
        color: offWhite,
    },
    // end dropdown info

    attendanceText: {
        color: lightGrey,
        display: 'flex'
    },
    details: {
        flexDirection: 'row',
        alignItems: 'center',
        width: "100%",
        backgroundColor: "none",
        paddingTop: 16,
    },
    nameHolder: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginLeft: 15,
        marginRight: 15,
        paddingVertical: 3,
        flex: 1
    },
    name: {
        fontSize: 14,
        fontFamily: font600,
        color: offWhite,
        color: '#fff',
        textTransform: 'capitalize'       
    },
    role:{         
        fontSize: 13,
        color: offWhite,
        fontFamily: font400,    
    },
    stateHolder: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingVertical: 3,
        flex: "0 0 auto"
    },  
    keyHolder:{
        position: "absolute",
        bottom: 0,
        right: -5
    },
    keyLogo: {
        width: 22,
        height: 10,
        marginLeft: 2,
        // marginRight: 10,
        opacity: 0.2,
        resizeMode: 'contain'
    },
    availability: {
        alignItems: "center"
    },
    daySplit: {
        fontFamily: font600,
        fontSize: 13,
        color: offWhite   
    },
    isInIcon: {
        height: 18,
        width: 18,
        backgroundColor: lightGrey,
        borderRadius: '50%',
        position: 'absolute',
        right: 0,
        bottom: -3,
        borderWidth: 2,
        borderColor: darkGrey
    },
    dogName: {
        color: lightGrey
    },
    dogPic: {
        marginLeft: 15,
        marginRight: 10,
    }
});

export default StaffSlice;