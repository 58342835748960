// Firebase Database
import { default as Firebase, db } from '../config/firebase';
import { AuthenticatedUserContext } from '../navigation/AuthenticatedUserProvider';

// Navigation
import { NavigationContainer } from '@react-navigation/native';
import { BottomTabBar, createBottomTabNavigator } from '@react-navigation/bottom-tabs';

// Components
import { StatusBar } from 'expo-status-bar';
import React, { useContext, useState, useEffect, Component, useCallback } from 'react';
import { StyleSheet, View, ScrollView, TouchableHighlight, Image, Platform, ImageBackground, Animated, TextInput, TouchableWithoutFeedback } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context'
import { Text, Modal, Card, Spinner, Calendar } from '@ui-kitten/components';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// Birthday Animation
import ConfettiCannon from 'react-native-confetti-cannon';

// Custom
import { StaffSlice, Button } from '../components';
import CustomTabBar from '../components/CustomTabBar';
import ProfileScreen from './ProfileScreen';
import CalendarScreen from './CalendarScreen';
import { borderColor, darkGrey, font300, font400, font500, font600, font700, grey, lightGrey, offWhite, uiGreen, uiOrange, uiRed, windowHeight, windowWidth } from '../styles'; // variables for gestures

// Fancy dates
import moment, { months } from "moment";
import { dark } from '@eva-design/eva';

// Swipey swipe
import { FlatList, useWindowDimensions } from 'react-native-web';

// Events from Outlook
import 'moment-timezone';

const auth = Firebase.auth();

export default function GoodStuff() {
    const Tab = React.useRef(createBottomTabNavigator()).current;
    const [dates, setDates] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedDay, setSelectedDay] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const { user } = useContext(AuthenticatedUserContext);
    const currentUID = user.uid;

    // Waiting for dates to change
    useEffect(() => {
        if (dates.length > 0 && selectedDay == 0) setSelectedDay(dates[0].uid); // set day to today/first in array
        // console.log(dates); //snapshot of all days with all users assigned
    }, [dates]);

    // Loaded once when loaded
    useEffect(() => {
        let timeToSearch = moment().set({ hour: moment().hour() >= 18 ? 18 : 7, minute: 0, seconds: 0 }).toDate();
        // console.log('Looking for dates >', timeToSearch.toDateString(), timeToSearch.toLocaleTimeString());

        const dayListener = db.collection('Days').where('date', '>', timeToSearch).orderBy('date').onSnapshot(snap => {
            const snapDates = snap.docs.map(doc => ({ uid: doc.id, data: doc.data() }))
            setDates(snapDates);
        });
        const userListener = db.collection('Users').onSnapshot(snap => {
            const snapUsers = snap.docs.map(doc => ({ uid: doc.id, data: doc.data() }))
            setUsers(snapUsers);
        });

        return () => [dayListener(), userListener()];
    }, []);

    const Blank = () => {
        return true
    }

    //TV Mode
    const isWeb = Platform.OS === "web" ? true : false;

    //Bottom Tab Bar
    return (
        <View style={{ flex: 1, backgroundColor: darkGrey, justifyContent: 'center', alignItems: 'center' }}>
            <ImageBackground source={isWeb ? require('../assets/desktop-bg-over.jpg') : null} resizeMode="cover" style={{ flex: 1, width: windowWidth, justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ flex: 1, width: "100%", maxWidth: isWeb ? 900 : windowWidth, maxHeight: isWeb ? 1020 : '100%', borderWidth: isWeb && windowWidth > 768 ? 2 : 0, borderColor: borderColor, borderRadius: 10, backgroundColor: darkGrey }}>
                    <NavigationContainer independent={true}>
                        <Tab.Navigator
                            tabBar={(props) => (
                                // <BottomTabBar  {...props} />
                                <CustomTabBar {...props} />
                            )}
                            screenOptions={{
                                tabBarShowLabel: false,
                                headerShown: false,
                                tabBarInactiveBackgroundColor: '#1B1B20',
                                tabBarActiveBackgroundColor: '#1B1B20',
                            }}
                        >
                            {/* Home Page */}
                            <Tab.Screen
                                name="Home"
                                children={({ navigation }) =>
                                    <HomeScreen
                                        users={users}
                                        dates={dates}
                                        selectedDay={selectedDay}
                                        setSelectedDay={setSelectedDay}
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        currentUID={currentUID}
                                        navigation={navigation}
                                    />
                                }
                                options={{
                                    tabBarIcon: ({ focused }) => (
                                        <Image
                                            style={styles.bottomBarIcon}
                                            source={require('../assets/icons/home.png')}
                                        />
                                    )
                                }}
                                listeners={{
                                    tabPress: e => {
                                        if (dates.length > 0) setSelectedDay(dates[0].uid);
                                    },
                                }}
                            />

                            {/* Attending Options */}
                            <Tab.Screen
                                name="Attending Options"
                                component={Blank}
                                options={{
                                    tabBarIcon: () => {
                                        const isBooked = dates.some(date => 
                                            date.uid === selectedDay && 
                                            (date.data.allDay.includes(currentUID) || 
                                             date.data.morning.includes(currentUID) || 
                                             date.data.afternoon.includes(currentUID))
                                        );
                                        return (
                                            <View style={[styles.bookInButton, isBooked && { backgroundColor: uiGreen }]}>
                                                <Image
                                                    style={[
                                                        styles.plusIcon,
                                                        showModal && !isBooked && { transform: [{ rotate: '45deg' }] }
                                                    ]}
                                                    source={isBooked ? require('../assets/icons/tick.png') : require('../assets/icons/expand.png')}
                                                />
                                                <Text style={{ color: isBooked ? offWhite : darkGrey, width: 'max-content', padding: 10 }}>{isBooked ? 'Booked in' : 'Book in'}</Text>
                                            </View>
                                        );
                                    },
                                    tabBarStyle: {
                                        backgroundColor: offWhite
                                    }
                                }}
                                listeners={({ navigation }) => ({
                                    tabPress: (e) => {
                                        e.preventDefault();
                                        navigation.navigate("Home");
                                        if (showModal === false) setShowModal(true); else setShowModal(false);
                                    },
                                })}
                            />

                            {/* Profile Page */}
                            <Tab.Screen
                                name="Profile Settings"
                                children={() => <ProfileScreen profile={users.filter(us => us.uid === currentUID)[0]} />}
                                options={{
                                    tabBarIcon: () => (
                                        <Image
                                            style={[{ borderRadius: 30, height: 30, width: 30 }]}
                                            defaultSource={require('../assets/icons/place.png')}
                                            source={{ uri: users.filter(user => user.uid == currentUID).length ? users.filter(user => user.uid == currentUID)[0].data.photoURL : 'https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg' }}
                                        />
                                    ),
                                    tabBarButton: () => null // hide icon in tab bar    
                                }}
                            />

                            <Tab.Screen
                                name="Calendar"
                                children={({ navigation }) =>
                                    <CalendarScreen
                                        users={users}
                                        dates={dates}
                                        selectedDay={selectedDay}
                                        setSelectedDay={setSelectedDay}
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        currentUID={currentUID}
                                        navigation={navigation}
                                    />}
                                options={{
                                    tabBarIcon: ({ focused }) => (
                                        <Image
                                            style={styles.bottomBarIcon}
                                            source={require('../assets/icons/calendar.png')}
                                        />
                                    )             
                                }}

                            />

                        </Tab.Navigator>
                    </NavigationContainer>
                </View>
            </ImageBackground>
        </View>
    )
    //end bottom tab bar
}

const HomeScreen = ({ users, dates, selectedDay, setSelectedDay, showModal, setShowModal, currentUID, navigation }) => {
    const [indexy, setIndexy] = useState(0);
    // Defaults for Modal
    const [attendanceChoice, setAttendanceChoice] = useState(3);
    const [inOutChoice, setInOutChoice] = useState(false);
    const [petChoice, setPetChoice] = useState(0);
    // Key holder banner 
    const [keysPresent, setKeysPresent] = useState(true);
    const [livePeople, setLivePeople] = useState([]);
    const todayObject = dates.filter(dates => dates.uid === selectedDay)[0]; //get today for pet attendance    
    const isToday = moment(todayObject?.data.date.seconds * 1000).isSame(new Date(), "day") || false; // boolean if day selected is present day
    const loggedInUser = users.filter(staff => currentUID == staff.uid)[0]?.data || {};
    const eventBackground = dates[indexy] && dates[indexy].data.specialEvent.backgroundColour;
    const { height, width } = useWindowDimensions();
    // Annual Leave
    const [peopleOnHols, setPeopleOnHols] = useState([]);
    const [loadingHols, setLoadingHols] = useState(true);
    const [holidayData, setHolidayData] = useState([]);
    const [remoteUsers, setRemoteUsers] = useState([]);
    // Bank Holidays
    const [bankHolidays, setBankHolidays] = useState([]);
    const [loadingBankHols, setLoadingBankHols] = useState(false);
    const [selectedBankHoliday, setSelectedBankHoliday] = useState(null);
    // Birthdays
    const [showConfetti, setShowConfetti] = useState(false);
    const [birthdayMessages, setBirthdayMessages] = useState([]);
    // Calendar Events
    const [events, setEvents] = useState([]);
    const [cachedEvents, setCachedEvents] = useState({});
    // Anniversary Events
    const [anniversaryMessages, setAnniversaryMessages] = useState([]);

    useEffect(() => {
        if (dates.findIndex(ti => ti.uid == selectedDay) == -1) return;
        setIndexy(dates.findIndex(ti => ti.uid == selectedDay));
    }, [selectedDay]);

    // Defaults for modal
    useEffect(() => {
        if (selectedDay !== 0) {
            const todayObject = dates.find(date => date.uid === selectedDay);
            if (todayObject) {
                const userAttendance = todayObject.data;
                if (userAttendance.allDay.includes(currentUID)) {
                    setAttendanceChoice(2);
                    setInOutChoice(true);
                } else if (userAttendance.morning.includes(currentUID)) {
                    setAttendanceChoice(0);
                    setInOutChoice(true);
                } else if (userAttendance.afternoon.includes(currentUID)) {
                    setAttendanceChoice(1);
                    setInOutChoice(true);
                } else {
                    setAttendanceChoice(3);
                    setInOutChoice(false);
                }

                if (userAttendance.pets && userAttendance.pets.includes(currentUID)) {
                    setPetChoice(1);
                } else {
                    setPetChoice(0);
                }
            }
        }
    }, [selectedDay, dates, currentUID]);

   // Fetch events from Outlook
    useEffect(() => {
        async function fetchEvents() {
            try {
                // Testing URL // const response = await fetch('http://localhost:5001/freestyle-booking/us-central1/fetchEvents');
                const response = await fetch('https://us-central1-freestyle-booking.cloudfunctions.net/fetchEvents');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const events = await response.json();
                setEvents(events);

                // Cache events by date
                const eventsByDate = events.reduce((acc, event) => {
                    let startDate = moment.tz(event.start.dateTime, 'UTC').tz('Europe/London');
                    let endDate = moment.tz(event.end.dateTime, 'UTC').tz('Europe/London');

                    // Adjust for all-day events that start at midnight
                    if (startDate.hour() === 0 && startDate.minute() === 0 && startDate.second() === 0) {
                        startDate = startDate.subtract(1, 'days');
                    }

                    // Expand multi-day events
                    while (startDate.isBefore(endDate)) {
                        const dateString = startDate.format('YYYY-MM-DD');
                        if (!acc[dateString]) acc[dateString] = [];
                        acc[dateString].push(event);
                        startDate = startDate.add(1, 'days');
                    }

                    return acc;
                }, {});
                setCachedEvents(eventsByDate);
            } catch (error) {
                console.error('Error fetching events:', error);
                // Set default state if fetch fails
                setEvents([]);
                setCachedEvents({});
            }
        }

        fetchEvents();
    }, []);

    // Filter events for the selected day
    const selectedDate = moment(dates.find(date => date.uid === selectedDay)?.data.date.seconds * 1000).format('YYYY-MM-DD');
    const eventsForSelectedDay = cachedEvents[selectedDate] || [];

    // No Keys present banner
    useEffect(() => {
        if (dates[indexy] && users.length) {
            let attenders = [...dates[indexy].data.morning, ...dates[indexy].data.afternoon, ...dates[indexy].data.allDay];
            let keyHolders = users.filter(us => us.data.keyHolder);

            if (attenders.length == 0 || keyHolders.filter(el => attenders.includes(el.uid)).length) {
                setKeysPresent(true); // banner wont show
            } else {
                setKeysPresent(false);
            }
        };
    });

    // Bank Holidays: get and parse data
    const getBankHols = async () => {
        setLoadingBankHols(true); // Set loadingBankHols to true before fetching data
        try {
            const response = await fetch('https://www.gov.uk/bank-holidays.json');
            const json = await response.json();
            const bankHols = json["england-and-wales"].events.map(({ title, date }) => {
                return {
                    title: title,
                    date: moment(date).format('YYYY-MM-DD') // Use 'YYYY-MM-DD' format for comparison
                };
            });
            setBankHolidays(bankHols);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingBankHols(false); // Set loadingBankHols to false after fetching data
        }
    };
    
    // Wait for Breathe data to be fetched
    const [isBreatheDataFetched, setIsBreatheDataFetched] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                // Fetch Breathe data
                const response = await fetch('https://freestyle-sandbox.azurewebsites.net/api/bookingcalendar');
                if (response.ok) {
                    const breatheData = await response.json();
                    setHolidayData(breatheData); // Cache the entire holiday data
                    setIsBreatheDataFetched(true);
                } else {
                    console.error('Request failed with status:', response.status);
                }
    
                // Fetch Bank Holidays
                const bankHolsResponse = await fetch('https://www.gov.uk/bank-holidays.json');
                const json = await bankHolsResponse.json();
                const bankHols = json["england-and-wales"].events.map(({ title, date }) => ({
                    title: title,
                    date: moment(date).format('YYYY-MM-DD') // Use 'YYYY-MM-DD' format for comparison
                }));
                setBankHolidays(bankHols);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchInitialData();
    }, []);
    
    useEffect(() => {
        if (isBreatheDataFetched) {
            const selectedDate = moment(dates.find(date => date.uid === selectedDay)?.data.date.seconds * 1000).format('YYYY-MM-DD');
            filterPeopleOnHols(selectedDate);
            checkBankHoliday(selectedDate);
        }
    }, [selectedDay, isBreatheDataFetched]);
    
    const filterPeopleOnHols = (selectedDate) => {
        const selectedMoment = moment(selectedDate, 'YYYY-MM-DD');
        const holidayBanner = holidayData
            .filter(person => {
                const startDate = moment(person.StartDate, 'M/D/YYYY h:mm:ss A', true);
                let endDate = moment(person.EndDate, 'M/D/YYYY h:mm:ss A', true);
    
                // Adjust end date if it is exactly at midnight
                if (endDate.format('HH:mm:ss') === '00:00:00') {
                    endDate = endDate.subtract(1, 'seconds');
                }
    
                return selectedMoment.isBetween(startDate, endDate, 'day', '[]');
            })
            .map(person => {
                const userName = person.Summary.split(' - ')[0].trim();
                const user = users.find(user => user.data.displayName.trim() === userName);
    
                return {
                    name: userName,
                    holidayLength: person.Length || 'Unknown', // Fallback to 'Unknown' if Length is blank
                    uid: user ? user.uid : undefined, // Add uid to the holidayBanner object
                    leaveType: person.Description || 'Unknown' 
                };
            });
    
        setPeopleOnHols(holidayBanner);
    };
    
    const checkBankHoliday = (selectedDate) => {
        const bankHoliday = bankHolidays.find(holiday => holiday.date === selectedDate);
        setSelectedBankHoliday(bankHoliday || null);
    };

    useEffect(() => {
        // Ensure Breathe data is fetched before filtering remote users
        if (isBreatheDataFetched) {
            const filteredRemoteUsers = users.filter(user => 
                !peopleOnHols.some(person => person.uid === user.uid) &&
                !dates[indexy]?.data.allDay.includes(user.uid) &&
                !dates[indexy]?.data.morning.includes(user.uid) &&
                !dates[indexy]?.data.afternoon.includes(user.uid)
            );
            setRemoteUsers(filteredRemoteUsers);
        }
    }, [isBreatheDataFetched, peopleOnHols, users, dates, indexy]);
    
    // Who's currently in the office? 
    useEffect(() => {
        function fetchWhosIn() {
            auth.currentUser.getIdToken()
            .then((idtoken) => {
                return fetchLive(idtoken);
            })
            .then((data) => {
                setLivePeople(data);
            });
        }

        // Get data immediately
        fetchWhosIn();

        // Check who's in every minute.
        const checkWhosIn = setInterval(() => {
            fetchWhosIn();
        }, 60000);

        return () => clearInterval(checkWhosIn);
    }, []);

    const fetchLive = async (token) => {
        if (!token) return [];
        try {
            return await fetch('https://whos-in.freestyle.agency/api/getActiveTeamMembers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authtoken': token
                },
            })
                .then(response => {
                    return response.json();
                }).then(data => {
                    return data;
                });
        } catch (error) {
            console.log('Problem getting live people', error);
            return [];
        }
    }    

    // Check for birthdays on initial load
    useEffect(() => {
        const selectedDate = moment(dates.find(date => date.uid === selectedDay)?.data.date.seconds * 1000);
        checkBirthdays(users, selectedDate);
    }, [users]);

    // Check for birthdays when the selected day changes
    useEffect(() => {
        const selectedDate = moment(dates.find(date => date.uid === selectedDay)?.data.date.seconds * 1000);
        checkBirthdays(users, selectedDate);
        checkAnniversaries(users, selectedDate);
    }, [selectedDay, users]);

    function checkBirthdays(users, date) {
        const selectedDate = moment(date);
        const selectedDateFormatted = selectedDate.format('MM-DD');
        const selectedDayOfWeek = selectedDate.day();

        let messages = [];

        users.forEach(user => {
            const dobTimestamp = user.data.DOB;
            const showDOB = user.data.showDOB;
            if (dobTimestamp && dobTimestamp.seconds && showDOB) {
                const dob = moment(dobTimestamp.seconds * 1000);
                const dobDate = dob.format('MM-DD');

                if (dobDate === selectedDateFormatted) {
                    messages.push({ message: `Happy Birthday ${user.data.nickname}`, date: selectedDate, id: user.uid, dayOfWeek: selectedDayOfWeek, photoURL: user.data.photoURL });
                    setShowConfetti(true);
                } else if (selectedDayOfWeek === 5) {
                    const saturday = selectedDate.clone().add(1, 'days');
                    const sunday = selectedDate.clone().add(2, 'days');
                    if (dobDate === saturday.format('MM-DD') || dobDate === sunday.format('MM-DD')) {
                        const dayOfWeek = dobDate === saturday.format('MM-DD') ? 6 : 0; // Saturday is 6, Sunday is 0
                        messages.push({ message: `Happy Birthday ${user.data.nickname} for ${dobDate === saturday.format('MM-DD') ? 'Saturday' : 'Sunday'}`, date: dob, id: user.uid, dayOfWeek, photoURL: user.data.photoURL });
                        setShowConfetti(true);
                    }
                }
            }
        });

        setBirthdayMessages(messages);
    }

    function checkAnniversaries(users, date) {
        const selectedDate = moment(date);
        const selectedDateFormatted = selectedDate.format('MM-DD');
        const selectedDayOfWeek = selectedDate.day();

        let messages = [];

        users.forEach(user => {
            const startDateTimestamp = user.data.startDate;
            if (startDateTimestamp && startDateTimestamp.seconds) {
                const startDate = moment(startDateTimestamp.seconds * 1000);
                const startDateFormatted = startDate.format('MM-DD');
                const yearsWorked = selectedDate.diff(startDate, 'years');

                if (startDateFormatted === selectedDateFormatted && yearsWorked > 0) {
                    messages.push({
                        message: `Happy ${yearsWorked} year work-iversary to ${user.data.nickname}`,
                        date: selectedDate,
                        id: user.uid,
                        dayOfWeek: selectedDayOfWeek
                    });
                    setShowConfetti(true);
                } else if (selectedDayOfWeek === 5) {
                    const saturday = selectedDate.clone().add(1, 'days');
                    const sunday = selectedDate.clone().add(2, 'days');
                    if (startDateFormatted === saturday.format('MM-DD') || startDateFormatted === sunday.format('MM-DD')) {
                        const dayOfWeek = startDateFormatted === saturday.format('MM-DD') ? 6 : 0;
                        const yearsWorked = (startDateFormatted === saturday.format('MM-DD') ? saturday : sunday).diff(startDate, 'years');
                        messages.push({
                            message: `Happy ${yearsWorked} year work-iversary to ${user.data.nickname} for ${startDateFormatted === saturday.format('MM-DD') ? 'Saturday' : 'Sunday'}`,
                            date: startDate,
                            id: user.uid,
                            dayOfWeek
                        });
                        setShowConfetti(true);
                    }
                }
            }
        });

        setAnniversaryMessages(messages);
    }
    
    const renderStaff = (staffID) => {
        if (!staffID || users.length == 0) return false;
        let currentUser = users.filter(staff => staffID == staff.uid);
        if (!currentUser.length) return false; 
        currentUser = currentUser[0];
        let hasPet = todayObject.data.pets && todayObject.data.pets.findIndex(petHavers => petHavers === currentUser.uid) >= 0 ? true : false;

        // Determine the type of attendance
        let typeOfAttendance = '';
        if (dates[indexy].data.allDay.includes(currentUser.uid)) {
            typeOfAttendance = 'All day';
        } else if (dates[indexy].data.morning.includes(currentUser.uid)) {
            typeOfAttendance = 'Morning';
        } else if (dates[indexy].data.afternoon.includes(currentUser.uid)) {
            typeOfAttendance = 'Afternoon';
        }

        // Determine if the user is booked in
        const isBookedIn = dates[indexy].data.allDay.includes(currentUser.uid) ||
        dates[indexy].data.morning.includes(currentUser.uid) ||
        dates[indexy].data.afternoon.includes(currentUser.uid);

        // Check if any of the user's MAC addresses are in the livePeople array
        const isRemote = !isBookedIn && !peopleOnHols.some(person => person.uid === currentUser.uid);
        const isInStudio = currentUser.data.mac_addr.some(mac => livePeople.some(person => person.mac.includes(mac)));

        return (
            (currentUser.uid == currentUID ? 
                <TouchableHighlight onPress={() => { setShowModal(!showModal) }} style={{ width: '100%' }} key={currentUser.uid}>
                    <StaffSlice 
                        staffInfo={currentUser} 
                        typeOfAttendance={typeOfAttendance} 
                        dogOwner={hasPet} 
                        livePeople={livePeople} 
                        isToday={isToday} 
                        isBookedIn={isBookedIn} 
                        currentUID={currentUID}
                        uid={currentUser.uid}
                        isRemote={isRemote}
                        isInStudio={isInStudio}
                    />
                </TouchableHighlight>
                :
                <StaffSlice 
                    key={currentUser.uid} 
                    staffInfo={currentUser} 
                    typeOfAttendance={typeOfAttendance}
                    dogOwner={hasPet} 
                    livePeople={livePeople} 
                    isToday={isToday} 
                    isBookedIn={isBookedIn} 
                    currentUID={currentUID}
                    uid={currentUser.uid}
                    isRemote={isRemote}
                    isInStudio={isInStudio}
                />
            )
        )
    }

    // Update DB with attendance for that day
    const updateAttendance = async (newChoice, petChoice, cb) => {
        let docToEdit = db.collection('Days').doc(selectedDay);
        let doc = await docToEdit.get();

        if (!doc.exists) {
            return false;
        } else {
            let copy = doc.data();
            if (newChoice == 0) {
                //remove me from all arrays except the one im choosing
                copy.allDay = copy.allDay.filter(item => item !== currentUID);
                copy.morning = [...copy.morning.filter(item => item !== currentUID), currentUID];
                copy.afternoon = copy.afternoon.filter(item => item !== currentUID);
            } else if (newChoice == 1) {
                copy.allDay = copy.allDay.filter(item => item !== currentUID);
                copy.morning = copy.morning.filter(item => item !== currentUID);
                copy.afternoon = [...copy.afternoon.filter(item => item !== currentUID), currentUID];
            } else if (newChoice == 2) {
                copy.allDay = [...copy.allDay.filter(item => item !== currentUID), currentUID];
                copy.morning = copy.morning.filter(item => item !== currentUID);
                copy.afternoon = copy.afternoon.filter(item => item !== currentUID);
            } else {
                //remove me from all arrays
                copy.allDay = copy.allDay.filter(item => item !== currentUID);
                copy.morning = copy.morning.filter(item => item !== currentUID);
                copy.afternoon = copy.afternoon.filter(item => item !== currentUID);
            }
            if (!copy.pets) copy.pets = [];
            if (petChoice === 1) {
                copy.pets = [...copy.pets.filter(item => item !== currentUID), currentUID];
            } else {
                copy.pets = copy.pets.filter(item => item !== currentUID);
            }
            db.collection('Days').doc(selectedDay).set(copy);
        }

        // N2H - show little toast notification of choice saved
        return cb && cb();
    }

    // Modal
    const AttendanceChoiceModal = () => {
        return (
            <TouchableWithoutFeedback onPress={() => setShowModal(false)}>
                <View style={styles.attendancePopupBackground}>
                    <TouchableWithoutFeedback onPress={() => {}}>
                    <View style={styles.attendancePopup}>
                        <View style={[{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: "#595959", paddingBottom: 7, marginBottom: 7 }]}>
                            <Text category={'h2'} style={[styles.dayName, { fontSize: 18, marginRight: 5 }]}>{selectedDay !== 0 && moment(dates.filter(date => date.uid == selectedDay)[0].data.date.seconds * 1000).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'dddd'
                            })}</Text>
                            <Text style={styles.date}>{selectedDay != 0 && moment(dates.filter(date => date.uid == selectedDay)[0].data.date.seconds * 1000).format('Do MMM')}</Text>
                        </View>
                        <View style={styles.attendanceOption}>
                            <View style={[styles.optionLabel, { flex: 1 }]}>
                                <Text style={styles.optionTitle} category='p1'>Are you planning to be in</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <View style={[styles.picker, !inOutChoice && styles.inactive]}>
                                    <TouchableHighlight onPress={() => {
                                        setInOutChoice(true);
                                        setAttendanceChoice(2);
                                    }}>
                                        <Text style={[styles.option, attendanceChoice == 3 && { color: grey }]}>Yes</Text>
                                    </TouchableHighlight>
                                </View>
                                <View style={[styles.picker, inOutChoice && styles.inactive]}>
                                    <TouchableHighlight onPress={() => {
                                        setInOutChoice(false);
                                        setAttendanceChoice(3);
                                    }}>
                                        <Text style={[styles.option, attendanceChoice != 3 && { color: grey }]}>No</Text>
                                    </TouchableHighlight>
                                </View>
                            </View>
                        </View>
                        {inOutChoice &&
                            <>
                                <View style={styles.attendanceOption}>
                                    <View style={styles.optionLabel}>
                                        <Text style={styles.optionTitle} category='p1'>I'm planning to be in</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'stretch' }}>
                                        <View style={[styles.picker, attendanceChoice != 0 && { borderBottomColor: grey }]}>
                                            <TouchableHighlight onPress={() => setAttendanceChoice(0)}>
                                                <Text style={[styles.option, attendanceChoice != 0 && { color: grey }]}>AM</Text>
                                            </TouchableHighlight>
                                        </View>
                                        <View style={[styles.picker, attendanceChoice != 1 && { borderBottomColor: grey }]}>
                                            <TouchableHighlight onPress={() => setAttendanceChoice(1)}>
                                                <Text style={[styles.option, attendanceChoice != 1 && { color: grey }]}>PM</Text>
                                            </TouchableHighlight>
                                        </View>
                                        <View style={[styles.picker, attendanceChoice != 2 && { borderBottomColor: grey }]}>
                                            <TouchableHighlight onPress={() => setAttendanceChoice(2)}>
                                                <View>
                                                    <Text style={[styles.option, attendanceChoice != 2 && { color: grey }]}>AM{"\n"}
                                                        PM</Text>
                                                </View>
                                            </TouchableHighlight>
                                        </View>
                                    </View>
                                </View>
                                {loggedInUser.hasPet &&
                                    <View style={styles.attendanceOption}>
                                        <View style={styles.optionLabel}>
                                            <Text style={styles.optionTitle} category='p1'>I'm bringing my dog</Text>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'stretch' }}>
                                            <View style={[styles.picker, petChoice === 0 && { borderBottomColor: grey }]}>
                                                <TouchableHighlight onPress={() => setPetChoice(1)}>
                                                    <Text style={[styles.option, petChoice === 0 && { color: grey }]}>Yes</Text>
                                                </TouchableHighlight>
                                            </View>
                                            <View style={[styles.picker, petChoice === 1 && { borderBottomColor: grey }]}>
                                                <TouchableHighlight onPress={() => setPetChoice(0)}>
                                                    <Text style={[styles.option, petChoice === 1 && { color: grey }]}>No</Text>
                                                </TouchableHighlight>
                                            </View>
                                        </View>
                                    </View>
                                }
                            </>
                        }
                        <View style={{ display: "flex", flexDirection: "row" }}>
                            <Button
                                type="outlined"
                                title='Cancel'
                                titleColor={offWhite}
                                onPress={() => { setShowModal(!showModal) }}
                                containerStyle={{marginRight: 10}}
                            />
                            <Button
                                type="filled"
                                title='Confirm'
                                titleColor={darkGrey}
                                onPress={() => updateAttendance(attendanceChoice, petChoice, () => { setShowModal(!showModal) })}
                            />
                        </View>
                    </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
        );
    }

    // Touch / Swipe functionality for switching days
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const minSwipeDistance = 50;
    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    };
    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
    const onTouchEnd = () => {

        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance

        let maxDays = dates.length && dates.length - 1; // 11
        let firstDay = dates.length > 0 && selectedDay === dates[0].uid;
        let lastDay = dates.length > 0 && selectedDay === dates[maxDays].uid;
        let currentDayIndex = dates.findIndex(dates => dates.uid === selectedDay);

        if (currentDayIndex < maxDays && isLeftSwipe) {
            dates.length && setSelectedDay(dates[currentDayIndex + 1].uid)
            // console.log('swipe', isLeftSwipe ? 'left' : 'right')
        } else if (currentDayIndex > 0 && isRightSwipe) {
            dates.length && setSelectedDay(dates[currentDayIndex - 1].uid)
            // console.log('swipe', isLeftSwipe ? 'left' : 'right')
        }
    };

    // Navigate Days
    useEffect(() => {
        // getPeopleOnHols(); // Fetch and cache all holiday data on page load
        getBankHols(); // Fetch bank holidays on page load
    }, []);
    
    useEffect(() => {
        const selectedDate = moment(dates.find(date => date.uid === selectedDay)?.data.date.seconds * 1000).format('YYYY-MM-DD');
        filterPeopleOnHols(selectedDate); // Filter cached data for the selected day
        checkBankHoliday(selectedDate); // Check if the selected date is a bank holiday
    }, [selectedDay]);

    const NavigateDays = () => {
        let maxDays = dates.length && dates.length - 1;
        let firstDay = dates.length > 0 && selectedDay === dates[0].uid;
        let lastDay = dates.length > 0 && selectedDay === dates[maxDays].uid;
        let currentDayIndex = dates.findIndex(dates => dates.uid === selectedDay);
        const { width } = useWindowDimensions();

        const handleDayChange = (newDay) => {
            setSelectedDay(newDay);
            const selectedDate = moment(dates.find(date => date.uid === newDay)?.data.date.seconds * 1000).format('YYYY-MM-DD');
            filterPeopleOnHols(selectedDate);
            checkBankHoliday(selectedDate);
            checkBirthdays(users, selectedDate);
            checkAnniversaries(users, selectedDate);

            // Check for cached events
            if (cachedEvents[selectedDate]) {
                setEvents(cachedEvents[selectedDate]);
            }
        };

        return (
            <div style={{ display: width > 797 ? 'flex' : 'none' }}>
                <TouchableHighlight onPress={() => { dates.length && handleDayChange(dates[currentDayIndex - 1].uid) }} style={[firstDay === true ? styles.disabledArrow : null]} disabled={firstDay === true ? true : false}>
                    <MaterialCommunityIcons name={'chevron-left'} size={40} color={offWhite} style={styles.arrows}></MaterialCommunityIcons>
                </TouchableHighlight>
                <TouchableHighlight onPress={() => { dates.length && handleDayChange(dates[currentDayIndex + 1].uid) }} style={[lastDay === true ? styles.disabledArrow : null]} disabled={lastDay === true ? true : false}>
                    <MaterialCommunityIcons name={'chevron-right'} size={40} color={offWhite} style={styles.arrows}></MaterialCommunityIcons>
                </TouchableHighlight>
            </div>
        );
    };

    const ghosts = livePeople.reduce((acc, attender) => {
        users.forEach(user => {
            if (attender.mac.includes(user.data.mac_addr) && ![...todayObject.data.morning, ...todayObject.data.afternoon, ...todayObject.data.allDay].includes(user.uid)) {
                acc.push(user.uid);
            }
        });
        return acc;
    }, []);

    const [filter, setFilter] = useState(null);

    // Filter users who are on annual leave
    const usersOnAnnualLeave = users.filter(user => peopleOnHols.includes(user.data.displayName));

    // Filter users who are in the studio
    const usersInStudio = [
        ...dates[indexy]?.data.allDay || [],
        ...dates[indexy]?.data.morning || [],
        ...dates[indexy]?.data.afternoon || [],
        ...livePeople.map(person => person.uid)
    ];

    // Filter for "Remote" category (all others)
    const usersNotInStudioOrOnLeave = users.filter(user => 
        !usersInStudio.includes(user.uid) && 
        !usersOnAnnualLeave.some(leaveUser => leaveUser.uid === user.uid) &&
        !livePeople.some(person => person.uid === user.uid) &&
        !peopleOnHols.some(person => person.uid === user.uid)
    );

    // Sort function to place current user first and then alphabetically
    const sortUsers = (userList) => {
        return userList.sort((a, b) => {
            if (a.uid === currentUID) return -1;
            if (b.uid === currentUID) return 1;
            return a.data.displayName.localeCompare(b.data.displayName);
        });
    };

    // Sort users in each section
    const sortedUsersInStudio = sortUsers(users.filter(user => 
        usersInStudio.includes(user.uid) || (isToday && ghosts.includes(user.uid))
    ));
    const sortedUsersOnAnnualLeave = sortUsers(users.filter(user => peopleOnHols.some(person => person.uid === user.uid)));
    const sortedUsersNotInStudioOrOnLeave = sortUsers(usersNotInStudioOrOnLeave.filter(user => !sortedUsersInStudio.includes(user)));

    // Function to render profile pictures in the filters
    const renderProfilePictures = (userList) => {
        const displayedUsers = userList.slice(0, 3);
        const remainingCount = userList.length - displayedUsers.length;

        return (
            <View style={styles.profilePicturesContainer}>
                {displayedUsers.map(user => (
                    <Image
                        key={user.uid}
                        style={styles.profilePicture}
                        source={{ uri: user.data.photoURL || 'https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg' }}
                    />
                ))}
                {remainingCount > 0 && (
                    <View style={styles.remainingCountContainer}>
                        <Text style={styles.remainingCountText}>+{remainingCount}</Text>
                    </View>
                )}
            </View>
        );
    };

    // Function to handle filter button click
    const handleFilterClick = (selectedFilter) => {
        setFilter(prevFilter => prevFilter === selectedFilter ? null : selectedFilter);
    };

    // Search box
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);

    const handleSearch = (text) => {
        setSearchQuery(text);
    };    

    const filterUsers = (users) => {
        if (!searchQuery) return users;
        return users.filter(user => 
            user.data.displayName.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };   

    const filteredUsersInStudio = filterUsers(sortedUsersInStudio);
    const filteredUsersOnAnnualLeave = filterUsers(sortedUsersOnAnnualLeave);
    const filteredUsersNotInStudioOrOnLeave = filterUsers(sortedUsersNotInStudioOrOnLeave);

    const handleClearFilters = () => {
        setFilter(null);
        setSearchQuery('');
    };

    if (loading) {
        return (
            <>
                <StatusBar style='dark' backgroundColor={darkGrey} />
                <View style={styles.loadingContainer}>
                    <Spinner size='giant' status='primary' />
                </View>
            </>
        );
    }

    return (
        <>
            <StatusBar style='dark' backgroundColor={darkGrey} />
            <SafeAreaView style={{ backgroundColor: darkGrey }} edges={['top']}>
                <View style={[styles.row, { paddingVertical: 15, paddingHorizontal: 18, borderBottomColor: '#595959', borderBottomWidth: 2 }]}>
                    <View style={styles.rowCal}>
                        <Image
                            style={styles.logo}
                            source={require('../assets/logo.png')}
                        />
                        {Platform.OS === "web" &&
                            <NavigateDays />
                        }
                        <View style={[styles.column, styles.left, { flex: 'inherit' }]}>
                            <Text category={'h2'} style={styles.dayName}>{selectedDay !== 0 && moment(dates.filter(date => date.uid == selectedDay)[0].data.date.seconds * 1000).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'dddd'
                            })}</Text>
                            <Text style={styles.date}>{selectedDay != 0 && moment(dates.filter(date => date.uid == selectedDay)[0].data.date.seconds * 1000).format('Do MMM')}</Text>
                        </View>
                        <TouchableHighlight style={styles.profileToggle} onPress={() => navigation.navigate("Profile Settings")}>
                            <Image
                                style={[{ borderRadius: 42, height: 42, width: 42 }]}
                                source={{ uri: users.filter(user => user.uid == currentUID).length ? users.filter(user => user.uid == currentUID)[0].data.photoURL : 'https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg' }}
                            />
                        </TouchableHighlight>
                    </View>
                </View>
                {/* Bank holiday banner - GREEN*/}
                {dates.length > 0 && selectedBankHoliday && (
                    <View style={[styles.banner, { backgroundColor: uiGreen }]}>
                        <Text style={[styles.bannerHeading, { color: offWhite, display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }]}>
                            Bank Holiday: {selectedBankHoliday.title}
                        </Text>
                    </View>
                )}
                {/* Event Banner (Outlook) */}
                {dates.length > 0 && eventsForSelectedDay.length > 0 && eventsForSelectedDay.map(event => {
                    const startTime = moment.tz(event.start.dateTime, 'UTC').tz('Europe/London');
                    const endTime = moment.tz(event.end.dateTime, 'UTC').tz('Europe/London');
                    const duration = moment.duration(endTime.diff(startTime));
                    const isAllDayEvent = duration.asHours() > 11;

                    return (
                        <View key={event.id} style={[styles.banner, { backgroundColor: uiGreen }]}>
                            <Text style={[styles.bannerHeading, { color: offWhite }]}>
                                {event.subject} {!isAllDayEvent && `(${startTime.format('h:mma')} - ${endTime.format('h:mma')})`}
                            </Text>
                        </View>
                    );
                })}
                {/* Custom Event Banner (Firebase) - GREEN */}
                {dates.length > 0 && dates[indexy].data.specialEvent && dates[indexy].data.specialEvent.eventName.length > 0 &&
                    <View style={[styles.banner, { backgroundColor: eventBackground == '' ? uiGreen : eventBackground }]}>
                        <Text style={[styles.bannerHeading, { color: eventBackground == 'white' ? darkGrey : offWhite }]}>{dates[indexy].data.specialEvent.eventName}</Text>
                    </View>
                }
                {/* Dogs banner - RED*/}
                {dates.length > 0 && dates[indexy].data.pets && dates[indexy].data.pets.length > 2 &&
                    <View style={[styles.banner, { backgroundColor: uiRed }]}>
                        <Text style={[styles.bannerHeading, { color: offWhite }]}>🚨 Max 2 dogs exceeded 🚨</Text>
                    </View>
                }
                {/* Birthday Banner */}
                {dates.length > 0 && birthdayMessages.length > 0 && (
                    birthdayMessages
                        .sort((a, b) => {
                            const dayOrder = { 5: 1, 6: 2, 0: 3 }; // Friday: 1, Saturday: 2, Sunday: 3
                            return dayOrder[a.dayOfWeek] - dayOrder[b.dayOfWeek];
                        })
                        .map((item) => (
                            <View key={item.id} style={[styles.banner, { backgroundColor: uiGreen }]}>
                                <Image
                                    style={styles.birthdayProfilePicture}
                                    source={{ uri: item.photoURL || 'https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg' }}
                                />
                                <Text style={[styles.bannerHeading, { color: offWhite, display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }]}>
                                    {item.message} 🎉
                                </Text>
                            </View>
                        ))
                )}
                {/* Anniversary Banner */}
                {dates.length > 0 && anniversaryMessages.length > 0 && (
                    anniversaryMessages
                        .sort((a, b) => {
                            const dayOrder = { 5: 1, 6: 2, 0: 3 }; // Friday: 1, Saturday: 2, Sunday: 3
                            return dayOrder[a.dayOfWeek] - dayOrder[b.dayOfWeek];
                        })
                        .map((item) => (
                            <View key={item.id} style={[styles.banner, { backgroundColor: uiGreen }]}>
                                <Text style={[styles.bannerHeading, { color: offWhite, display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }]}>
                                    🎉 {item.message} 🎉
                                </Text>
                            </View>
                        ))
                )}
            </SafeAreaView>
            <ScrollView 
                showsVerticalScrollIndicator={false} 
                style={styles.container} 
                contentContainerStyle={styles.contentContainer}
                onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>

            {/* Filter Buttons */}
            <View style={styles.filterContainer}>
                <TouchableHighlight 
                    style={[styles.filterButton, filter === 'studio' && styles.activeFilterButton, filter && filter !== 'studio' && styles.inactiveFilterButton]} 
                    onPress={() => handleFilterClick('studio')}>
                    <View style={styles.filterButtonContent}>
                        <Text style={styles.filterButtonCount}>{filteredUsersInStudio.length}</Text>
                        <Text style={styles.filterButtonText}>Studio</Text>
                        {renderProfilePictures(filteredUsersInStudio)}
                    </View>
                </TouchableHighlight>
                <TouchableHighlight 
                    style={[styles.filterButton, filter === 'remote' && styles.activeFilterButton, filter && filter !== 'remote' && styles.inactiveFilterButton]} 
                    onPress={() => handleFilterClick('remote')}>
                    <View style={styles.filterButtonContent}>
                        <Text style={styles.filterButtonCount}>{filteredUsersNotInStudioOrOnLeave.length}</Text>
                        <Text style={styles.filterButtonText}>Remote</Text>
                        {renderProfilePictures(filteredUsersNotInStudioOrOnLeave)}
                    </View>
                </TouchableHighlight>
                <TouchableHighlight 
                    style={[styles.filterButton, filter === 'onLeave' && styles.activeFilterButton, filter && filter !== 'onLeave' && styles.inactiveFilterButton]} 
                    onPress={() => handleFilterClick('onLeave')}>
                    <View style={styles.filterButtonContent}>
                        <Text style={styles.filterButtonCount}>{filteredUsersOnAnnualLeave.length}</Text>
                        <Text style={styles.filterButtonText}>On Leave</Text>
                        {renderProfilePictures(filteredUsersOnAnnualLeave)}
                    </View>
                </TouchableHighlight>
            </View>

            {/* Search Bar */}
            <View style={styles.searchBarContainer}>
                <View style={[styles.searchBar, (isSearchActive || searchQuery) ? styles.searchBarActive : null]}>
                    <MaterialCommunityIcons name="magnify" size={20} color={grey} style={styles.searchIcon} />
                    <TextInput
                        style={styles.searchInput}
                        placeholder="Find someone"
                        placeholderTextColor={grey}
                        value={searchQuery}
                        onChangeText={handleSearch}
                        onFocus={() => setIsSearchActive(true)}
                        onBlur={() => setIsSearchActive(false)}
                    />
                </View>
                <TouchableHighlight
                    style={[
                        styles.clearFilterButton,
                        (filter || searchQuery) && styles.activeButton
                    ]}
                    onPress={handleClearFilters}
                    disabled={!filter && !searchQuery}
                >
                    <Text style={[
                        styles.clearFilterButtonText,
                        (filter || searchQuery) && styles.activeButtonText
                    ]}>Clear filters</Text>
                </TouchableHighlight>
            </View>

            {dates.length > 0 ? (
                <View>
                    {/* In the Studio Section */}
                    {(filter === null || filter === 'studio') && (
                        <View style={[styles.column, styles.mb, { paddingHorizontal: 18, width: '100%' }]}>
                            <View style={[styles.sectionHead, { width: '100%' }]}>
                                <Text category="h3" style={styles.sectionHeading}>In the Studio</Text>
                                {/* <View style={{ flexDirection: 'row' }}>
                                    <Text style={styles.dayCount}>AM: {dates[indexy].data.morning.length}</Text>
                                    <Text style={[styles.dayCount, { marginLeft: 10 }]}>PM: {dates[indexy].data.afternoon.length}</Text>
                                    <Text style={[styles.dayCount, { marginLeft: 10 }]}>
                                        All day: {dates[indexy].data.allDay.length + (isToday ? ghosts.length : 0)}
                                    </Text>
                                    {dates[indexy].data.pets && dates[indexy].data.pets.length > 0 &&
                                        <Text style={[styles.dayCount, { marginLeft: 10 }]}>Dogs: {dates[indexy].data.pets.length}</Text>
                                    }
                                </View> */}
                            </View>
                            {(filter === null || filter === 'studio') &&
                                filteredUsersInStudio.length > 0 ? (
                                    filteredUsersInStudio.map(user => renderStaff(user.uid))
                                ) : (
                                    <Text style={styles.noUsersText}>No one booked in yet</Text>
                                )
                            }
                        </View>
                    )}
                    {/* Remote Section */}
                    {(filter === null || filter === 'remote') && (
                        <View style={[styles.column, styles.mb, { paddingHorizontal: 18, width: '100%' }]}>
                            <View style={[styles.sectionHead, { width: '100%' }]}>
                                <Text category="h3" style={styles.sectionHeading}>Remote</Text>
                                {/* <Text style={styles.dayCount}>Total: {filteredUsersNotInStudioOrOnLeave.length}</Text> */}
                            </View>
                            {filteredUsersNotInStudioOrOnLeave.length > 0 ? (
                                filteredUsersNotInStudioOrOnLeave.map(user => renderStaff(user.uid))
                            ) : (
                                <Text style={styles.noUsersText}>No one here</Text>
                            )}
                        </View>
                    )}
                    {/* On Leave Section */}
                    {(filter === null || filter === 'onLeave') && (
                        <View style={[styles.column, styles.mb, { paddingHorizontal: 18, width: '100%' }]}>
                            <View style={[styles.sectionHead, { width: '100%' }]}>
                                <Text category="h3" style={styles.sectionHeading}>On Leave</Text>
                                {/* <Text style={styles.dayCount}>Total: {isBreatheDataFetched ? filteredUsersOnAnnualLeave.length : 'Loading...'}</Text> */}
                            </View>
                            {isBreatheDataFetched ? (
                                filteredUsersOnAnnualLeave.length > 0 ? (
                                    filteredUsersOnAnnualLeave.map(user => {
                                        const personOnLeave = peopleOnHols.find(person => person.name === user.data.displayName);
                                        const holidayType = personOnLeave ? personOnLeave.holidayLength : 'Unknown';
                                        const leaveType = personOnLeave ? personOnLeave.leaveType : 'Unknown';

                                        return (
                                            <View key={user.uid} style={styles.staffContainer}>
                                                <StaffSlice staffInfo={user} holidayType={holidayType} leaveType={leaveType} />
                                            </View>
                                        );
                                    })
                                ) : (
                                    <Text style={styles.noUsersText}>No one on Annual Leave</Text>
                                )
                            ) : (
                                <View style={styles.loadingContainer}>
                                    <Spinner size='small' status='primary' />
                                </View>
                            )}
                        </View>
                    )}
                </View>
            ) : (
                <View style={[styles.column, styles.mb, { paddingHorizontal: 12 }]}>
                    <Spinner size='giant' status='danger' />
                </View>
            )}
            </ScrollView>

            {showModal &&
                <AttendanceChoiceModal />
            }

            {dates.length > 0 && showConfetti && (
                <ConfettiCannon
                    count={400}
                    origin={{ x: -10, y: 0 }}
                    fadeOut={true}
                    explosionSpeed={1000}
                    onAnimationEnd={() => {
                        setShowConfetti(false);
                    }}
                />
            )}
        </>
    );
}

const styles = StyleSheet.create({

    loadingContainer: {
        backgroundColor: darkGrey,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },

    staffContainer: {
        width: '100%'
    },

    // Filters
    filterContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: darkGrey,
        marginTop: 15,
        marginHorizontal: 18,
    },
    filterButton: {
        backgroundColor: darkGrey,
        borderColor: grey,
        borderWidth: 2,
        padding: 10,
        borderRadius: 12,
        alignItems: 'center',
        width: 'calc(33% - 4px)',
    },
    activeFilterButton: {
        borderColor: offWhite,
    },
    inactiveFilterButton: {
        opacity: 0.3,
    },
    filterButtonContent: {
        width: '100%'
    },
    filterButtonText: {
        color: offWhite,
        fontSize: 16,
        fontFamily: font600,
    },
    filterButtonCount: {
        color: lightGrey,
        fontSize: 14,
        fontFamily: font600,
    },
    profilePicturesContainer: {
        flexDirection: 'row',
        marginTop: 7,
    },
    profilePicture: {
        width: 23,
        height: 23,
        borderRadius: 12,
        marginRight: -5,
    },
    remainingCountContainer: {
        width: 23,
        height: 23,
        borderRadius: 12,
        backgroundColor: grey,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    remainingCountText: {
        color: offWhite,
        fontSize: 9,
        fontFamily: font400,
    },
    // end filters
    // Search bar
    searchBarContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginHorizontal: 18,
    },
    searchBar: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: darkGrey,
        borderColor: grey,
        borderWidth: 2,
        borderRadius: 46,
        paddingHorizontal: 8,
        marginVertical: 8,
        flex: 1
    },
    searchBarActive: {
        borderColor: offWhite,
    },
    searchIcon: {
        // marginRight: 10,
    },
    searchInput: {
        color: offWhite,
        fontSize: 16,
        fontFamily: font400,
        borderWidth: 0,
        padding: 10,
        width: '100%',
        ...Platform.select({
            web: {
                outlineStyle: 'none', // Remove outline on web
            },
        }),
    },
    clearFilterButton: {
        marginVertical: 8,
        width: 'max-content',
        marginLeft: 8,
        alignItems: 'center',
    },
    clearFilterButtonText: {
        backgroundColor: 'rgba(89, 89, 89, 0.40)',
        color: grey,
        fontSize: 14,
        fontFamily: font400,
        padding: 15,
        width: 'max-content',
        borderRadius: 60,
        lineHeight: "1"
    },
    activeButtonText: {
        backgroundColor: offWhite,
        color: darkGrey,
    },
    noUsersText: {
        color: grey,
        fontSize: 16,
        fontFamily: font400,
        textAlign: 'center',
        marginTop: 10,
    },
    // End search bar
    container: {
        flex: 1,
        backgroundColor: darkGrey,
        paddingTop: Platform.OS == "android" ? 50 : 0,
        fontFamily: font400,
    },
    contentContainer: {
        paddingBottom: 24
    },
    noFlow: {
        overflow: "hidden"
    },
    navButton: {
        backgroundColor: darkGrey,
        paddingVertical: 25,
    },
    logo: {
        marginRight: 15,
        height: 44,
        width: 41
    },
    arrows: {
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backdrop: {
        backgroundColor: "rgba(0,0,0, 0.5)"
    },
    calendarToggle: {
        backgroundColor: grey,
        borderRadius: 50,
        padding: 2,
        display: "flex",
        flexDirection: "row",
        marginLeft: 'auto'
    },
    profileToggle: {
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        marginLeft: 'auto'
    },
    toggleItem: {
        backgroundColor: grey,
        borderRadius: 30,
        padding: 15,
        paddingLeft: 10
    },
    toggleItemActive: {
        backgroundColor: darkGrey,
        padding: 15,
        paddingLeft: 15
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rowCal: {
        width: "100%",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    column: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    left: {
        alignItems: 'flex-start'
    },
    banner: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: darkGrey
    },
    bannerHeading: {
        color: offWhite,
        fontSize: 12,
        fontFamily: font600
    },
    sectionHead: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 16,
        borderTopWidth: 2,
        borderTopColor: grey,
        paddingTop: 16
    },
    // "Planning to be in"
    sectionHeading: {
        color: offWhite,
        fontSize: 12,
        fontFamily: font600
    },
    dayName: {
        fontSize: 20,
        fontFamily: font600,
        color: offWhite,
        marginBottom: 3
    },
    date: {
        fontSize: 16,
        fontFamily: font300,
        color: offWhite
    },
    bottomBarIcon: {
        height: 42,
        width: 42
    },
    plusIcon: {
        width: 25,
        height: 25
    },
    dateItem: {
        paddingVertical: 5,
        textAlign: "center",
        fontSize: 16,
        fontFamily: font600
    },
    actionButtonIcon: {
        fontSize: 20,
        height: 22,
        color: offWhite,
        zIndex: 99
    },
    dayContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1,
    },
    value: {
        fontSize: 12,
        fontFamily: font400
    },
    //Attendance Modal
    attendancePopupBackground: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        width: "100%",
        justifyContent: 'flex-end',
        height: windowHeight,
        position: 'absolute',
        left: 0,
        bottom: 0,
        zIndex: 999
    },
    attendancePopup: {
        boxShadow: '0px 4px 64px 0px #00000080',
        backgroundColor: darkGrey,
        paddingHorizontal: 18,
        paddingTop: 18,
        paddingBottom: 10
    },
    attendanceOption: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
        maxHeight: 70
    },
    optionLabel: {
        flex: 1,
        fontSize: 13,
        color: offWhite,
        fontFamily: font500,
    },
    picker: {
        fontFamily: font700,
        color: offWhite,
        borderBottomColor: offWhite,
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 50,
        minWidth: 50
    },
    inactive: {
        borderBottomWidth: 1,
        borderBottomColor: grey,
        color: grey
    },
    option: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        fontFamily: font700,
        fontSize: 12,
        color: offWhite
    },
    dayCount: {
        fontFamily: font400,
        fontSize: 12,
        color: offWhite
    },
    pageContainer: {
        ...Platform.select({
            web: {
                width: 900,
                maxHeight: 1080
            }
        })
    },
    disabledArrow: {
        opacity: .3,
    },
    bookInButton: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        backgroundColor: offWhite,
        borderRadius: 60,
        paddingLeft: 8
    },
    birthdayProfilePicture: {
        width: 40,
        height: 40,
        borderRadius: 40,
        marginRight: 10,
    },

    bottomBarIcon: {
        height: 42,
        width: 42,
    },
});