import React from 'react';
import { StyleSheet, Pressable, Text } from 'react-native';
import { darkGrey, font700, offWhite } from '../styles';

const Button = ({
  title,
  type = 'filled', // default type
  backgroundColor = '#000',
  titleColor = 'black',
  titleSize = 14,
  onPress,
  width = 'auto',
  containerStyle
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={args => {
        const baseStyle = [
          styles.base,
          {
            backgroundColor: type === 'filled' ? backgroundColor : 'transparent',
            width
          },
          containerStyle
        ];

        if (args.pressed) {
          baseStyle.push({ opacity: 0.5 });
        }

        if (type === 'outlined') {
          baseStyle.push(styles.outlinedButton);
        } else {
          baseStyle.push(styles.filledButton);
        }

        return baseStyle;
      }}
    >
      <Text style={[styles.text, { color: titleColor, fontSize: titleSize }]}>
        {title}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: 700
  },
  base: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 42,
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 12
  },
  filledButton: {
    backgroundColor: offWhite,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: offWhite,
    borderRadius: 0,
    marginVertical: 10,
    flex: 1
  },
  outlinedButton: {
      backgroundColor: darkGrey,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: offWhite,
      borderRadius: 0,
      marginVertical: 10,
      flex: 1
  },
});

export default Button;