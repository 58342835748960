import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View, Button as RNButton, Image, Dimensions, Platform, Appearance } from 'react-native';

import { Button, InputField, ErrorMessage, SuccessMessage } from '../components';
import Firebase from '../config/firebase';

const win = Dimensions.get('window');
const ratio = win.width/750;
const auth = Firebase.auth();

// const colorScheme = Appearance.getColorScheme();

export default function ResetPasswordScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [resetSuccess, setResetSuccess] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [resetError, setResetError] = useState('');

  const triggerResetEmail = async () => {
    try {
      if (email !== '') {
        await Firebase.auth().sendPasswordResetEmail(email);
        setResetSuccess(true);
        setResetMessage("If you are registered, we will send you an email with a link to reset your password.");
      } else {
        setResetError("Please enter an email address");
        setResetSuccess(false);
      }
    } catch (error) {
      setResetError(error.message);
      setResetSuccess(false);
    }
  }

  return (
    <View style={styles.container}>
      <StatusBar style='dark' />
      <Image 
        style={styles.signupSplash}
        source={require('../assets/login-splash.png')} 
      />
      <View style={styles.curvedContainer}>
        <View style={[styles.rowContainer, {marginBottom: 20}]}>
          <Text style={[styles.title, {marginBottom: 5}]}>Welcome to Freestyle</Text>
          <Text style={styles.subtitle}>Reset your password</Text>
        </View>
        <View style={styles.columnContainer}>
          <InputField
            inputStyle={{
              fontSize: 14,
              backgroundColor: '#b0b0a726',
              color: '#B0B0A7',
              textAlign: 'center'
            }}
            containerStyle={{
              marginHorizontal: 55,
              alignSelf: 'stretch'
            }}
            autoCapitalize='none'
            keyboardType='email-address'
            textContentType='emailAddress'
            autoFocus={true}
            value={email}
            placeholder='Email Address'
            placeholderTextColor={'#B0B0A7'}
            onChangeText={text => setEmail(text)}
          />
        </View>
        <View style={styles.columnContainer}>
          {resetSuccess === false ? <ErrorMessage error={resetError} visible={true} /> : null}
          <Button
            onPress={triggerResetEmail}
            backgroundColor='#fff'
            title='Reset Password'
            tileColor='#1B1B20'
            titleSize={14}
            containerStyle={{
              marginHorizontal: 55,
              alignSelf: 'stretch'
            }}            
          />
        </View>   
        {resetSuccess === true ? <SuccessMessage success={resetMessage} visible={true} /> : null}   
        <Button
            onPress={() => navigation.navigate('Login')}
            title='Back to Log In'
            titleSize={14}
            titleColor='#e3e3e3'
            backgroundColor='none'
            containerStyle={{
              marginHorizontal: 55,   
              alignSelf: 'stretch'           
            }}
          />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1B1B20',
  },
  rowContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  curvedContainer: {
    minWidth: 364,
    alignSelf: 'center',
    marginTop: Platform.OS == "web" ? 30 : 0
  },  
  columnContainer: {
    marginTop: 8,
    alignItems: 'center'
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    alignSelf: 'center',
    color: '#fff',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '400',
    alignSelf: 'center',
    color: '#fff',
    textAlign: 'center'
  },
  signupSplash: {
    width: win.width > 400 ? 400 : win.width,
    height:  win.width > 400 ? 250 : 574 * ratio,
    marginHorizontal: 'auto'
  },
  inputHead: {
    fontSize: 13,
    fontWeight: '600',
    marginBottom: 6,
    textAlign: 'center',
    color: '#B0B0A7'
  },
});