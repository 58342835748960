import { Dimensions } from "react-native";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

// Colours
const grey = "#595959";
const darkGrey = "#1B1B20";
const offWhite = "#F9F7EF";
const uiRed = "#A52038";
const uiOrange = "#EBA048";
const uiGreen = "#1B6546";
const borderColor = "#595959";
// const lightGrey = '#E3E1DA';
const lightGrey = '#B0B0A7'

// Fonts
const font100 = "Inter_100Thin";
const font200 = "Inter_200ExtraLight";
const font300 = "Inter_300Light";
const font400 = "Inter_400Regular";
const font500 = "Inter_500Medium";
const font600 = "Inter_600SemiBold";
const font700 = "Inter_700Bold";
const font800 = "Inter_800ExtraBold";
const font900 = "Inter_900Black";

export {
  darkGrey,
  offWhite,
  font100,
  font200,
  font300,
  font400,
  font500,
  font600,
  font700,
  font800,
  font900,
  grey,
  windowHeight,
  windowWidth,
  uiRed,
  uiOrange,
  uiGreen,
  borderColor,
  lightGrey
};
