import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View, Button as RNButton, Image, Dimensions, Platform, Appearance  } from 'react-native';

import { Button, InputField, ErrorMessage } from '../components';
import Firebase from '../config/firebase';

const auth = Firebase.auth();
const win = Dimensions.get('window');
const ratio = win.width/750;

export default function SignupScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState('eye');
  const [signupError, setSignupError] = useState('');

  // Toggle password visibility on sign up screen
  const handlePasswordVisibility = () => {
    if (rightIcon === 'eye') {
      setRightIcon('eye-off');
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === 'eye-off') {
      setRightIcon('eye');
      setPasswordVisibility(!passwordVisibility);
    }
  };

  // Validation for non freestyle email addresses
  const onHandleSignup = async () => {
    if(email.toLowerCase().indexOf("@freestyle.agency") === -1){
      //signs up but doesnt let you log in
      return setSignupError("Only @freestyle.agency emails allowed");
    }
    try {
      if (email !== '' && password !== '') {
        await auth.createUserWithEmailAndPassword(email, password)
      }
    } catch (error) {
      setSignupError(error.message);
    }
  };

  return (
    <View style={styles.container}>
      <StatusBar style='dark' />
      <Image 
        style={styles.signupSplash}
        source={require('../assets/login-splash.png')} 
      />
      <View style={styles.curvedContainer}>
      <View style={[styles.rowContainer, {marginBottom: 20}]}>
          <Text style={[styles.title, {marginBottom: 5}]}>Welcome to Freestyle</Text>
          <Text style={styles.subtitle}>Create a new account</Text>
        </View>
        <View style={styles.columnContainer}>
          <InputField
            inputStyle={{
                fontSize: 14,
                backgroundColor: '#b0b0a726',
                color: '#B0B0A7',
                textAlign: 'center'
              }}
              containerStyle={{
                marginHorizontal: 55,   
                alignSelf: 'stretch'           
              }}
              autoCapitalize='none'
              keyboardType='email-address'
              textContentType='emailAddress'
              autoFocus={true}
              value={email}
              placeholder='Enter Email Address'
              placeholderTextColor={'#B0B0A7'}
              onChangeText={text => setEmail(text)}
            />
        </View>
        <View style={styles.columnContainer}>
          <InputField
            inputStyle={{
              fontSize: 14,
              backgroundColor: '#b0b0a726',
              color: '#B0B0A7',
              textAlign: 'center'
            }}
            containerStyle={{
              marginHorizontal: 55,   
              alignSelf: 'stretch'           
            }}
            color='black'
            autoCapitalize='none'
            autoCorrect={false}
            secureTextEntry={passwordVisibility}
            textContentType='password'
            value={password}
            placeholder="Enter Password"
            placeholderTextColor={'#B0B0A7'}
            onChangeText={text => setPassword(text)}
            rightIcon={rightIcon}
            handlePasswordVisibility={handlePasswordVisibility}
            onSubmitEditing={onHandleSignup}
          />
        </View>
        <View style={styles.columnContainer}>
          {signupError ? <ErrorMessage error={signupError} visible={true} /> : null}
          <Button
            onPress={onHandleSignup}
            backgroundColor='white'
            title='Sign Up'
            titleSize={14}
            containerStyle={{
              marginHorizontal: 55,   
              alignSelf: 'stretch'           
            }}
            />
          <Button
            onPress={() => navigation.navigate('Login')}
            title='Already registered? Log In'
            titleSize={14}
            titleColor='#e3e3e3'
            backgroundColor='none'
            containerStyle={{
              marginHorizontal: 55,   
              alignSelf: 'stretch'           
            }}
            />
        
          </View>
        </View>
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1B1B20',
  },
  rowContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  curvedContainer: {
    minWidth: 364,
    alignSelf: 'center',
    marginTop: Platform.OS == "web" ? 30 : 0
  },  
  columnContainer: {
    marginTop: 8,
    alignItems: 'center'
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    alignSelf: 'center',
    color: '#fff',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '400',
    alignSelf: 'center',
    color: '#fff',
    textAlign: 'center'
  },
  signupSplash: {
    width: win.width > 400 ? 400 : win.width,
    height:  win.width > 400 ? 250 : 574 * ratio,
    marginHorizontal: 'auto'
  },
  inputHead: {
    fontSize: 13,
    fontWeight: '600',
    marginBottom: 6,
    textAlign: 'center',
    color: '#B0B0A7'
  },
  buttonContainer: {
    marginTop: 35
  }
});