// Firebase Database
import {default as Firebase, db} from '../config/firebase';
import { AuthenticatedUserContext } from '../navigation/AuthenticatedUserProvider';

// Components
import { StatusBar } from 'expo-status-bar';
import React, { useContext, useState, useEffect, Component, useCallback } from 'react';
import { StyleSheet, View, ScrollView, TouchableHighlight, Image, Platform, ImageBackground, Animated } from 'react-native';
import { Text, Button} from '@ui-kitten/components';
import { SafeAreaView } from 'react-native-safe-area-context'

// Custom
import { borderColor, darkGrey, font300, font400, font500, font600, font700, grey, lightGrey, offWhite, uiGreen, windowHeight, windowWidth } from '../styles'; // variables for gestures

// Fancy dates
import moment from "moment"; 
import CalendarPicker from 'react-native-calendar-picker';
import { dark } from '@eva-design/eva';

const auth = Firebase.auth(); 

const CalendarScreen = ({ users, dates, selectedDay, setSelectedDay, showModal, setShowModal, navigation }) => { 

    const { user } = useContext(AuthenticatedUserContext);
    const currentUID = user.uid;
    const docToEdit = db.collection('Users').doc(currentUID);
    const isWeb = Platform.OS === "web" ? true : false;

    return <>
        <StatusBar style='dark' backgroundColor={darkGrey}/>
        <SafeAreaView style={{ backgroundColor: darkGrey }} edges={['top']}>
            <View style={[styles.row, {paddingVertical: 15, paddingHorizontal: 18, borderBottomColor: '#595959', borderBottomWidth: 2}]}>
                <View style={styles.rowCal}>
                    <Image 
                        style={styles.logo}
                        source={require('../assets/logo.png')}
                    />
                    <View style={[styles.column, styles.left]}>
                        <Text category={'h2'} style={styles.dayName}>{selectedDay !== 0 && moment(dates.filter(date => date.uid == selectedDay)[0].data.date.seconds * 1000).calendar(null, {
                                    sameDay: '[Today]',
                                    nextDay: '[Tomorrow]',
                                    nextWeek: 'dddd',
                                    lastDay: '[Yesterday]',
                                    lastWeek: 'dddd',
                                    sameElse: 'dddd'
                                })}</Text>
                        <Text style={styles.date}>{selectedDay != 0 && moment(dates.filter(date => date.uid == selectedDay)[0].data.date.seconds * 1000).format('Do MMM')}</Text>
                    </View>
                    <TouchableHighlight style={styles.profileToggle} onPress={() => navigation.navigate("Profile Settings")}>
                        <Image
                            style={[{ borderRadius: 42, height: 42, width: 42 }]}
                            defaultSource={require('../assets/icons/place.png')}
                            source={{ uri: users.filter(user => user.uid == currentUID).length ? users.filter(user => user.uid == currentUID)[0].data.photoURL : 'https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg' }}
                        />
                    </TouchableHighlight>
                </View>
            </View>
        </SafeAreaView>
        <ScrollView style={{flex: 1, flexGrow: 1, flexShrink: 0, backgroundColor: darkGrey}} edges={['top']}>
            <View style={{marginTop: 12}}>
                <CalendarPicker
                    startFromMonday={true}
                    scaleFactor={windowWidth > 900 ? 500 : 280}
                    width={windowWidth > 900 ? 900 : windowWidth}
                    weekdays={['MON', 'TUE', 'WED', 'THU', 'FRI','SAT','SUN']}
                    previousTitle={""}
                    nextTitle={""}
                    // Update selected date with calendar clicked date
                    onDateChange={(date) => {
                        let selectedDayInSeconds = moment(date).set({"hour": 8, "minute": 0, "second": 0}).unix();
                        let compareDays = dates.filter(dates => moment(dates.data.date.seconds * 1000).set({"hour": 8, "minute": 0, "second": 0}).unix() == selectedDayInSeconds)[0].uid; // compare selected Day in seconds --> list of days in seconds and retrieves UID of that day
                        setSelectedDay(compareDays);
                        navigation.navigate("Home");
                    }}
                    // Current selected date to be highlighted on calendar open 
                    selectedStartDate={selectedDay != 0 && moment(dates.filter(date => date.uid == selectedDay)[0].data.date.seconds * 1000)}
                    // Min/max date works with restrictMonthNavigation to only show relevant months
                    minDate={moment().set({"hour": 8, "minute": 0, "second": 0})}
                    maxDate={moment().add(14, 'days')}
                    restrictMonthNavigation={true}
                    // Remove weekends
                    disabledDates={date => {
                        let Saturday = 6;
                        let Sunday = 0;
                        if (date.day() === Saturday || date.day() === Sunday) {
                            return true // weekend disabled
                        } else {
                            return false // all others enabled
                        }
                    }}
                    scrollable={true} // otherwise only clickable via hyperlink
                    horizontal={false} // needs scrollable=true to work
                    customDatesStyles={(date) => {
                        switch(date.isoWeekday()) {
                            case 6:
                            case 7:
                            return {
                                containerStyle:{
                                display: 'none',
                                },
                                allowDisabled: true
                            };
                        }
                        const dateInSeconds = moment(date).set({"hour": 8, "minute": 0, "second": 0}).unix();
                        const dayData = dates.find(d => moment(d.data.date.seconds * 1000).set({"hour": 8, "minute": 0, "second": 0}).unix() === dateInSeconds);
                        if (dayData) {
                            if (dayData.data.allDay.includes(currentUID)) {
                                return {
                                    style: {
                                        borderColor: uiGreen,
                                        borderWidth: 3,
                                        borderRadius: '50%',
                                        // height: 42,
                                        // width: 42,
                                        alignSelf: 'center'
                                    }
                                };
                            } else if (dayData.data.morning.includes(currentUID)) {
                                return {
                                    style: {
                                        borderRightColor: uiGreen,
                                        borderTopColor: uiGreen,
                                        borderLeftColor: darkGrey,
                                        borderBottomColor: darkGrey,
                                        borderWidth: 3,
                                        transform: [{ rotate: '45deg' }],
                                        borderRadius: '50%',
                                        // height: 42,
                                        // width: 42,
                                        alignSelf: 'center'
                                    },
                                    textStyle: {
                                        transform: [{ rotate: '-45deg' }],
                                    }
                                };
                            } else if (dayData.data.afternoon.includes(currentUID)) {
                                return {
                                    style: {
                                        borderRightColor: darkGrey,
                                        borderTopColor: darkGrey,
                                        borderLeftColor: uiGreen,
                                        borderBottomColor: uiGreen,
                                        borderWidth: 3,
                                        transform: [{ rotate: '45deg' }],
                                        borderRadius: '50%',
                                        // height: 42,
                                        // width: 42,
                                        alignSelf: 'center'
                                    },
                                    textStyle: {
                                        transform: [{ rotate: '-45deg' }],
                                    }
                                };
                            }
                        }
                        return {};
                    }}
                    customDayHeaderStyles={(dayOfWeek, month, year) => {
                        switch(dayOfWeek.dayOfWeek) {
                            case 6:
                            case 7:
                            return {
                                textStyle:{
                                    display: 'none',
                                },
                                allowDisabled: true
                            };
                        }
                    }}
                    // Styling
                    textStyle={{
                        color: offWhite,
                    }}
                    style={{
                        color: offWhite,
                        borderColor: grey,
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderRadius: 1000,
                    }}
                    todayBackgroundColor={darkGrey}
                    selectedDayStyle={{
                        padding: '7px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: grey
                    }}
                    selectedDayTextColor={offWhite}
                    disabledDatesTextStyle={{
                        color: grey,
                        borderColor: darkGrey,
                        borderWidth: 0,
                        borderStyle: 'none'
                    }}
                    todayTextStyle={{
                        color: offWhite
                    }}
                    hideEmptyDisabledWeekendRow                       
                />
            </View>                            
        </ScrollView>         
    </>
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: darkGrey, 
        paddingTop: Platform.OS == "android" ? 50 : 10,        
        fontFamily: font400
    },
    contentContainer:{
        marginBottom: 24
    },
    noFlow: {
        overflow: "hidden"
    },
    navButton: {
        backgroundColor: darkGrey, 
        paddingVertical: 25,
    },
    logo: {
        marginRight: 15,
        height: 44,
        width: 41
    },
    backdrop: {
        backgroundColor: "rgba(0,0,0, 0.5)"
    },
    profileToggle: {
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        marginLeft: 'auto'
    },
    toggleItem: {
        backgroundColor: grey,
        borderRadius: 30,
        padding: 15,
        paddingRight: 10
    },
    toggleItemActive: {
        backgroundColor: darkGrey,
        padding: 15,
        paddingRight: 15
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rowCal: {
        width: "100%",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    column: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1
    },
    left: {
        alignItems: 'flex-start'
    },
    sectionHead: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 25,
        marginBottom: 10
    },  
    sectionHeading: {
        color: offWhite,
        fontSize: 14,       
        fontFamily: font600
    },
    dayName:{
        fontSize: 20,
        fontFamily: font600,
        color: offWhite,
        marginBottom: 3
    },
    date: {
        fontSize: 16,
        fontFamily: font300,
        color: offWhite
    },
    profileIcon: {
        width: 25,
        height: 25
    },
    dateItem: {
        paddingVertical: 5,
        textAlign: "center",
        fontSize: 16,
        fontFamily: font600
    },
    actionButtonIcon: {
        fontSize: 20,
        height: 22,
        color: offWhite,
        zIndex: 99
    },
    dayContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1,
    },
    value: {
        fontSize: 12,        
        fontFamily: font400
    },
    dayCount:{
        fontFamily: font400,
        fontSize: 14,
        color: offWhite
    },
    pageContainer: {
        ...Platform.select({
            web:{
                width: 900, 
                maxHeight: 1080
            }
        })
    }
});

export default CalendarScreen;
