import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { useState } from 'react';
import { StyleSheet, Text, View, Button as RNButton, Image, Dimensions, Platform, Appearance, TouchableOpacity } from 'react-native';

import { Button, InputField, ErrorMessage } from '../components';
import Firebase from '../config/firebase';

const auth = Firebase.auth();
const win = Dimensions.get('window');
const ratio = win.width/750;

// const colorScheme = Appearance.getColorScheme();

export default function LoginScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState('eye');
  const [loginError, setLoginError] = useState('');

  const handlePasswordVisibility = () => {
    if (rightIcon === 'eye') {
      setRightIcon('eye-off');
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === 'eye-off') {
      setRightIcon('eye');
      setPasswordVisibility(!passwordVisibility);
    }
  };

  const onLogin = async () => {
    try {
      if (email !== '' && password !== '') {
        await auth.signInWithEmailAndPassword(email, password);
      }
    } catch (error) {
      setLoginError(error.message);
    }
  };

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent")
  }

  return (
    <View style={styles.container}>
      <StatusBar style='dark' />
      <Image 
        style={styles.signupSplash}
        source={require('../assets/login-splash.png')} 
      />
      <View style={styles.curvedContainer}>
        <View style={[styles.rowContainer, {marginBottom: 20}]}>
          <Text style={[styles.title, {marginBottom: 5}]}>Welcome to Freestyle</Text>
          <Text style={styles.subtitle}>Sign in to continue</Text>
        </View>
        <View style={styles.columnContainer}>
          <InputField
            inputStyle={{
              fontSize: 14,
              backgroundColor: '#b0b0a726',
              color: '#B0B0A7',
              textAlign: 'center'
            }}
            containerStyle={{
              marginHorizontal: 55,
              alignSelf: 'stretch'
            }}
            autoCapitalize='none'
            keyboardType='email-address'
            textContentType='emailAddress'
            autoFocus={true}
            value={email}
            placeholder='Email Address'
            placeholderTextColor={'#B0B0A7'}
            onChangeText={text => setEmail(text)}
          />
        </View>
        <View style={styles.columnContainer}>
          <InputField
            inputStyle={{
              fontSize: 14,
              backgroundColor: '#b0b0a726',
              color: '#B0B0A7',
              textAlign: 'center'
            }}
            containerStyle={{
              marginHorizontal: 55,   
              alignSelf: 'stretch'           
            }}
            color='black'
            autoCapitalize='none'
            autoCorrect={false}
            secureTextEntry={passwordVisibility}
            textContentType='password'
            value={password}
            placeholder="Password"
            placeholderTextColor={'#B0B0A7'}
            onChangeText={text => setPassword(text)}
            rightIcon={rightIcon}
            handlePasswordVisibility={handlePasswordVisibility}
            onSubmitEditing={onLogin}
          />
        </View>
        <View style={styles.columnContainer}>
          {loginError ? <ErrorMessage error={loginError} visible={true} /> : null}
          <Button
            type="filled"
            onPress={onLogin}
            backgroundColor='#fff'
            title='Log In'
            tileColor='#1B1B20'
            titleSize={14}
            containerStyle={{
              marginHorizontal: 55,
              alignSelf: 'stretch'
            }}            
          />
          <TouchableOpacity onPress={() => navigation.navigate('ResetPassword')} style={styles.resetPasswordButton}>
            <Text style={styles.resetPasswordText}>Forgot my password</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('Signup')} style={styles.signupButton}>
            <Text style={styles.signupButtonText}>Need an account? Register Now</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1B1B20',
  },
  rowContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  curvedContainer: {
    minWidth: 364,
    alignSelf: 'center',
    marginTop: Platform.OS == "web" ? 30 : 0
  },  
  columnContainer: {
    marginTop: 8,
    alignItems: 'center'
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    alignSelf: 'center',
    color: '#fff',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '400',
    alignSelf: 'center',
    color: '#fff',
    textAlign: 'center'
  },
  signupSplash: {
    width: win.width > 400 ? 400 : win.width,
    height:  win.width > 400 ? 250 : 574 * ratio,
    marginHorizontal: 'auto'
  },
  inputHead: {
    fontSize: 13,
    fontWeight: '600',
    marginBottom: 6,
    textAlign: 'center',
    color: '#B0B0A7'
  },
  resetPasswordButton: {
    marginHorizontal: 55,
    alignSelf: 'stretch',
    paddingTop: 10,
    alignItems: 'center',
  },
  resetPasswordText: {
    color: '#e3e3e3',
    fontSize: 14,
  },
  signupButton: {
    marginHorizontal: 55,
    alignSelf: 'stretch',
    paddingTop: 10,
    alignItems: 'center',
  },
  signupButtonText: {
    color: '#e3e3e3',
    fontSize: 14,
  },
});