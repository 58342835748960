import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Image, ScrollView, TouchableHighlight, Platform, SafeAreaView, Alert } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import { darkGrey, grey, font300, font400, font500, font600, font700, offWhite, lightGrey } from '../styles';
import { StatusBar } from 'expo-status-bar';
import { Picker } from '@react-native-picker/picker';
import { Button } from '../components';

// Firebase Database
import { default as Firebase, db } from '../config/firebase';
import { AuthenticatedUserContext } from '../navigation/AuthenticatedUserProvider';
import { TextInput } from 'react-native-web';

// Add image upload functionality
import 'firebase/storage';
import { launchImageLibraryAsync, MediaTypeOptions } from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

const auth = Firebase.auth();

const ProfileScreen = ({ profile }) => {
    const { user } = useContext(AuthenticatedUserContext);
    const currentUID = user.uid;
    const docToEdit = db.collection('Users').doc(currentUID);
    const profileDeets = profile;
    const [nickname, setNickname] = useState('');
    const [jobRole, setJobRole] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [displayDOB, setDisplayDOB] = useState(false);
    const [yearsOfService, setYearsOfService] = useState('');
    const [photoURL, setPhotoURL] = useState('');

    // Save button - check for changes
    const [initialNickname, setInitialNickname] = useState('');
    const [initialJobRole, setInitialJobRole] = useState('');
    const [initialDogName, setInitialDogName] = useState('');

    // Upload image func
    const [dogImageUrl, setDogImageUrl] = useState(null);
    const [dogName, setDogName] = useState('');

    const handleSignOut = async () => {
        try {
            await auth.signOut();
        } catch (error) {
            console.log(error);
        }
    };

    const onNicknameSubmitEdit = () => {
        docToEdit.update({ nickname: nickname });
    };
    const onJobRoleSubmitEdit = () => {
        docToEdit.update({ role: jobRole });
    }
    const onDogNameSubmitEdit = () => {
        docToEdit.update({ dogName: dogName });
    };
    const onSave = async () => {
        const updates = {};
        if (nickname !== initialNickname) {
            updates.nickname = nickname;
        }
        if (jobRole !== initialJobRole) {
            updates.role = jobRole;
        }
        if (dogName !== initialDogName) {
            updates.dogName = dogName;
        }
    
        if (Object.keys(updates).length > 0) {
            await docToEdit.update(updates);
            alert('Changes saved!');
        } else {
            alert('No changes to save.');
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const doc = await docToEdit.get();
            const data = doc.data();
            if (data) {
                setPhotoURL(data.photoURL || 'https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg');
                setDogImageUrl(data.dogPhotoURL || require("../assets/paw-profile.png"));
                setInitialNickname(data.nickname || '');
                setNickname(data.nickname || '');
                setInitialJobRole(data.role || '');
                setJobRole(data.role || '');
                setInitialDogName(data.dogName || '');
                setDogName(data.dogName || '');
                if (data.DOB) {
                    const dob = new Date(data.DOB.seconds * 1000);
                    setDay(dob.getDate().toString().padStart(2, '0'));
                    setMonth((dob.getMonth() + 1).toString().padStart(2, '0'));
                }
                if (data.showDOB !== undefined) {
                    setDisplayDOB(data.showDOB);
                }
                if (data.startDate) {
                    const startDate = new Date(data.startDate.seconds * 1000);
                    setYearsOfService(calculateYearsOfService(startDate));
                }
            }
        };
        fetchUserData();
    }, []);

    const updateDOB = async (newDay, newMonth) => {
        const doc = await docToEdit.get();
        const data = doc.data();
        if (data && data.DOB) {
            const dob = new Date(data.DOB.seconds * 1000);
            const year = dob.getFullYear();
            const updatedDOB = new Date(year, newMonth - 1, newDay);
            await docToEdit.update({ DOB: updatedDOB });
        }
    };

    const toggleDisplayDOB = async () => {
        const newDisplayDOB = !displayDOB;
        setDisplayDOB(newDisplayDOB);
        await docToEdit.update({ showDOB: newDisplayDOB });
    };

    const onDayChange = (itemValue) => {
        setDay(itemValue);
        updateDOB(itemValue, month);
    }

    const onMonthChange = (itemValue) => {
        setMonth(itemValue);
        updateDOB(day, itemValue);
    }

    const days = Array.from({ length: 31 }, (_, i) => ({
        itemName: (i + 1).toString().padStart(2, '0')
    }));

    const months = [
        { itemCode: '01', itemName: "Jan" },
        { itemCode: '02', itemName: "Feb" },
        { itemCode: '03', itemName: "Mar" },
        { itemCode: '04', itemName: "Apr" },
        { itemCode: '05', itemName: "May" },
        { itemCode: '06', itemName: "Jun" },
        { itemCode: '07', itemName: "Jul" },
        { itemCode: '08', itemName: "Aug" },
        { itemCode: '09', itemName: "Sep" },
        { itemCode: '10', itemName: "Oct" },
        { itemCode: '11', itemName: "Nov" },
        { itemCode: '12', itemName: "Dec" }
    ];

    function calculateYearsOfService(startDate) {
        const today = new Date();
        const years = today.getFullYear() - startDate.getFullYear();
        const months = today.getMonth() - startDate.getMonth();
        return `${years} years ${months} months`;
    }

    const pickAndUploadImage = async (isDogImage = false) => {
        const doc = await docToEdit.get();
        const data = doc.data();

        let result = await launchImageLibraryAsync({
            mediaTypes: MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.canceled) {
            const uri = result.assets[0].uri;
            const displayName = data.displayName || 'default_name';
            const formattedDisplayName = displayName.toLowerCase().replace(/\s+/g, '_');

            // Transform the image (resize and compress)
            const manipResult = await ImageManipulator.manipulateAsync(
                uri,
                [{ resize: { width: 100 } }],
                { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG } // Compress to 70% quality
            );

            const filename = `${new Date().getTime()}.jpg`;
            const folder = isDogImage ? 'dogImages' : 'profilePictures';
            const storageRef = Firebase.storage().ref(`${folder}/${formattedDisplayName}/${filename}`);

            // Fetch the transformed image as a blob using XMLHttpRequest
            const blob = await new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.onload = function() {
                    resolve(xhr.response);
                };
                xhr.onerror = function() {
                    reject(new TypeError("Network request failed"));
                };
                xhr.responseType = 'blob';
                xhr.open("GET", manipResult.uri, true);
                xhr.send(null);
            });

            // Upload the image with metadata
            const task = storageRef.put(blob, { contentType: 'image/jpeg' });

            task.on('state_changed', 
                (snapshot) => {
                    console.log(snapshot.totalBytes);
                }, 
                (err) => {
                    console.log(err);
                }, 
                async () => {
                    const downloadURL = await task.snapshot.ref.getDownloadURL();
                    console.log(downloadURL);

                    // Update Firestore with the image URL
                    const updateData = isDogImage ? { dogPhotoURL: downloadURL } : { photoURL: downloadURL };
                    await docToEdit.update(updateData);

                    // Update the state with the new image URL
                    if (isDogImage) {
                        setDogImageUrl(downloadURL);
                        alert('Dog image uploaded successfully!');
                    } else {
                        setPhotoURL(downloadURL);
                        alert('Profile image uploaded successfully!');
                    }
                }
            );
        }
    };

    return (
        <>
            <StatusBar style='dark' backgroundColor={darkGrey} />
            <SafeAreaView style={{ flex: 1, backgroundColor: darkGrey }}>
                <ScrollView style={styles.container}>
                    <View style={[styles.row, { paddingVertical: 15, paddingHorizontal: 18, borderBottomColor: '#595959', borderBottomWidth: 2 }]}>
                        <View style={styles.rowCal}>
                            <Image
                                style={styles.logo}
                                source={require('../assets/logo.png')}
                            />
                            <View style={[styles.column, styles.left]}>
                                <Text category={'h2'} style={styles.profileHeading}>Profile</Text>
                                <Text style={styles.profileName}>Hey, {profileDeets.data.nickname}👋🏼</Text>
                            </View>
                            <TouchableHighlight style={styles.profileToggle}>
                                <Image
                                    style={[{ borderRadius: 42, height: 42, width: 42 }]}
                                    defaultSource={require('../assets/icons/place.png')}
                                    source={{ uri: photoURL }}
                                />
                            </TouchableHighlight>
                        </View>
                    </View>
                    <View style={[styles.column, styles.mb, { paddingHorizontal: 18 }]}>
                    {/* <Text style={styles.subheading}>Years of service: {yearsOfService}</Text> */}

                        <Text style={styles.subheading}>Preferences</Text>
                        {/* Name */}
                        <View>
                            <View style={styles.optionLabel}>
                                <Text style={styles.optionTitle} category='p1'>Display Name:</Text>
                            </View>
                            <View>
                                <TextInput
                                    defaultValue={profileDeets.data.nickname}
                                    onChangeText={newText => setNickname(newText)}
                                    onSubmitEditing={onNicknameSubmitEdit}
                                    selectTextOnFocus={true}
                                    color={offWhite}
                                    textAlign={'right'}
                                    selectionColor={offWhite}
                                    placeholderTextColor={offWhite}
                                    style={[styles.textInput]}
                                ></TextInput>
                            </View>
                        </View>
                        {/* Role */}
                        <View>
                            <View style={styles.optionLabel}>
                                <Text style={styles.optionTitle} category='p1'>Role:</Text>
                            </View>
                                <View>
                                    <TextInput
                                        defaultValue={profileDeets.data.role}
                                        onChangeText={newText => setJobRole(newText)}
                                        onSubmitEditing={onJobRoleSubmitEdit}
                                        selectTextOnFocus={true}
                                        color={offWhite}
                                        textAlign={'right'}
                                        selectionColor={offWhite}
                                        placeholderTextColor={offWhite}
                                        style={[styles.textInput]}
                                    ></TextInput>
                                </View>
                        </View>
                        {/* Profile pic */}
                        <View>
                        <View style={styles.optionLabel}>
                                <Text style={styles.optionTitle} category='p1'>Your profile pic:</Text>
                            </View>
                            <View style={styles.dogImageContainer}>
                                <TouchableHighlight onPress={() => pickAndUploadImage(false)} style={styles.changePictureButton}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Icon name='cloud-upload-outline' style={{ width: 20, height: 20, marginRight: 5 }} fill={lightGrey} />
                                        <Text style={styles.changePictureText}>Change picture</Text>
                                    </View>
                                </TouchableHighlight>
                                <Image
                                    source={{ uri: profileDeets.data.photoURL }}
                                    style={styles.dogImage}
                                    />
                            </View>
                        </View>
                        {/* Birthday */}
                        <View style={styles.booleanOption}>
                            <View style={styles.optionLabel}>
                                <Text style={styles.optionTitle} category='p1'>Display your birthday in the app?</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'stretch' }}>
                                <View style={[styles.picker, !displayDOB && { borderBottomColor: grey }]}>
                                    <TouchableHighlight onPress={() => toggleDisplayDOB(true)}>
                                        <Text style={[styles.option, !displayDOB && { color: grey }]}>Yes</Text>
                                    </TouchableHighlight>
                                </View>
                                <View style={[styles.picker, displayDOB && { borderBottomColor: grey }]}>
                                    <TouchableHighlight onPress={() => toggleDisplayDOB(false)}>
                                        <Text style={[styles.option, displayDOB && { color: grey }]}>No</Text>
                                    </TouchableHighlight>
                                </View>
                            </View>
                        </View>
                        {displayDOB && (
                            <View style={styles.booleanOption}>
                                <View style={styles.optionLabel}>
                                    <Text style={styles.optionTitle} category='p1'>Birthday:</Text>
                                </View>
                                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'stretch' }}>
                                    <Picker
                                        mode="dropdown"
                                        selectedValue={day}
                                        onValueChange={onDayChange}
                                        style={{
                                            backgroundColor: darkGrey,
                                            color: offWhite,
                                            padding: 10,
                                            borderColor: darkGrey,
                                        }}
                                    >
                                        {days.map((item, index) => (
                                            <Picker.Item
                                                key={index}
                                                label={item.itemName}
                                                value={item.itemName}
                                            />
                                        ))}
                                    </Picker>
                                    <Picker
                                        mode="dropdown"
                                        selectedValue={month}
                                        onValueChange={onMonthChange}
                                        style={{
                                            backgroundColor: darkGrey,
                                            color: offWhite,
                                            padding: 10,
                                            borderColor: darkGrey,
                                        }}
                                    >
                                        {months.map((item, index) => (
                                            <Picker.Item
                                                key={index}
                                                label={item.itemName}
                                                value={item.itemCode}
                                            />
                                        ))}
                                    </Picker>
                                </View>
                            </View>
                        )}
                        {/* Dog */}
                        <View style={[styles.booleanOption, styles.dogSection]}>
                            <View style={styles.optionLabel}>
                                <Text style={styles.optionTitle} category='p1'>Do you own a dog?</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'stretch' }}>
                                <View style={[styles.picker, profileDeets.data.hasPet == false && { borderBottomColor: grey }]}>
                                    <TouchableHighlight onPress={() => docToEdit.update({ hasPet: true })}>
                                        <Text style={[styles.option, profileDeets.data.hasPet == false && { color: grey }]}>Yes</Text>
                                    </TouchableHighlight>
                                </View>
                                <View style={[styles.picker, profileDeets.data.hasPet == true && { borderBottomColor: grey }]}>
                                    <TouchableHighlight onPress={() => docToEdit.update({ hasPet: false })}>
                                        <Text style={[styles.option, profileDeets.data.hasPet == true && { color: grey }]}>No</Text>
                                    </TouchableHighlight>
                                </View>
                            </View>
                        </View>
                        {profileDeets.data.hasPet == true && 
                        <View>
                            <View style={styles.optionLabel}>
                                <Text style={styles.optionTitle} category='p1'>Your dog's name:</Text>
                            </View>
                            <View>
                                <TextInput
                                    defaultValue={profileDeets.data.dogName}
                                    onChangeText={newText => setDogName(newText)}
                                    onSubmitEditing={onDogNameSubmitEdit}
                                    selectTextOnFocus={true}
                                    color={offWhite}
                                    textAlign={'right'}
                                    selectionColor={offWhite}
                                    placeholderTextColor={offWhite}
                                    style={[styles.textInput]}
                                    ></TextInput>
                            </View>
                            <View style={styles.optionLabel}>
                                <Text style={styles.optionTitle} category='p1'>Your dog's profile pic:</Text>
                            </View>
                            <View style={styles.dogImageContainer}>
                                <TouchableHighlight onPress={() => pickAndUploadImage(true)} style={styles.changePictureButton}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Icon name='cloud-upload-outline' style={{ width: 20, height: 20, marginRight: 5 }} fill={lightGrey} />
                                        <Text style={styles.changePictureText}>Change picture</Text>
                                    </View>
                                </TouchableHighlight>
                                <Image
                                    source={{ uri: profileDeets.data.dogPhotoURL }}
                                    style={styles.dogImage}
                                    />
                            </View>
                        </View>
                        }
                        <View style={styles.buttonContainer}>
                            <Button
                                type="filled"
                                title='Save'
                                onPress={onSave}
                                containerStyle={{marginRight: 10}}
                                titleColor={darkGrey}
                                />
                            <Button
                                type="outlined"
                                title='Sign Out'
                                onPress={handleSignOut}
                                containerStyle={styles.outlinedButton}
                                titleColor={offWhite}
                            />
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: darkGrey,
        fontFamily: font400
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rowCal: {
        width: "100%",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    column: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1
    },
    left: {
        alignItems: 'flex-start'
    },
    logo: {
        marginRight: 15,
        height: 44,
        width: 41
    },
    profileName: {
        fontSize: 16,
        fontFamily: font300,
        color: offWhite,
        textTransform: 'capitalize'
    },
    profileHeading: {
        fontSize: 20,
        fontFamily: font600,
        marginBottom: 3,
    },
    profileToggle: {
        borderRadius: 50,
        display: "flex",
        flexDirection: "row",
        marginLeft: 'auto'
    },
    logoutIcon: {
        marginLeft: 15,
        height: 24,
        width: 27
    },
    // "Preferences"
    subheading: {
        color: offWhite,
        fontSize: 18,
        fontFamily: font600,
        borderBottomWidth: 2,
        borderBottomColor: grey,
        marginTop: 18,
        paddingBottom: 10,
        marginBottom: 15
    },
    p: {
        fontSize: 16,
        fontFamily: font300,
        color: offWhite
    },
    booleanOption: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
        maxHeight: 70
    },
    optionLabel: {
        flex: 1,
        fontSize: 13,
        color: offWhite,
        fontFamily: font500,
        marginBottom: 8
    },
    picker: {
        fontFamily: font700,
        color: offWhite,
        borderBottomColor: offWhite,
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 50,
        minWidth: 50
    },
    textInput: {
        color: offWhite,
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 50,
        padding: 10,
        outlineColor: lightGrey,
        outlineWidth: 1,
        outlineStyle: 'solid',
        marginBottom: 15
    },
    inactive: {
        borderBottomWidth: 1,
        borderBottomColor: grey,
        color: grey
    },
    option: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        fontFamily: font700,
        fontSize: 12,
        color: offWhite
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 35,
    },
    dogImageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 35,
    },
    dogImage: {
        width: 40,
        height: 40,
        borderRadius: 25,
        marginLeft: 10,
    },
    changePictureButton: {
        borderWidth: 1,
        borderColor: lightGrey,
        padding: 10,
        flex: 1,
        borderRadius: 0,
        flexDirection: 'row',
        alignItems: 'center',
    },
    changePictureText: {
        color: lightGrey,
        marginLeft: 5,
    },
    dogSection: {
        marginTop: 5,
    }
});

export default ProfileScreen;